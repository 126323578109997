import React, { Fragment, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { userSupportActions } from "../../state/ducks/userSupport";
import {
  AmountFormat,
  ContentHeader,
  ExportExcel,
  Filter,
  FormElement,
  Loading,
  TableList,
  TransactionRow,
} from "../../components";
import { initTimer, stopTimer } from "../../util/initTimer";
import "./styles.scss";
import { SIZE_PER_PAGE, TOAST_CONFIG } from "../../config/constants";
import { authActions } from "../../state/ducks/auth";
import { usersActions } from "../../state/ducks/users";
import { dashboardActions } from "../../state/ducks/dashboard";

const VirtualAccountBalance = (props) => {
  const {
    t,
    auth: {
      account: { product: currency },
    },
    users: {
      loading,
      optionsTypePerson,
      listVirtualAcounts: { list, totalElements },
    },
    dashboard: { balance },
    fetchTypePerson,
    fetchListVirtualAccounts,
    showMessage,
  } = props;

  const [cvu, setCvu] = useState("");
  const [cuit, setCuit] = useState("");
  const [alias, setAlias] = useState("");
  const [name, setName] = useState("");
  const [typePerson, setTypePerson] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    stopTimer();
    initTimer(props);
    fetchTypePerson();
    callApiList();
  }, []);

  useEffect(() => {
    stopTimer();
    initTimer(props);
    callApiList();
  }, [page]);

  const onChangePage = (selectPage) => {
    setPage(selectPage ? selectPage.selected : 0);
  };

  const callApiList = (getExcel = false) => {
    fetchListVirtualAccounts({
      data: {
        document_number: cuit,
        cvu,
        name,
        person_type: typePerson,
        alias,
        export: getExcel,
        page: getExcel ? 0 : page,
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
      },
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t("connectionError"),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  const handleInputChange = (event, set) => {
    stopTimer();
    initTimer(props);
    const {
      target: { value: targetValue },
    } = event;
    set(targetValue);
  };

  const onSubmit = (event) => {
    stopTimer();
    initTimer(props);
    callApiList();
    event.preventDefault();
    onChangePage();
  };

  const balanceFormat =
    balance !== 0 && balance !== undefined ? balance.replace(".", ",") : 0;

  return (
    <div>
      {loading && <Loading />}
      <Fragment>
        <div className='wow animated slideInDown'>
          <div className='wow animated slideInDown'>
            <Filter
              title={t("balanceAccounts")}
              subtitle={t("filters")}
              onSubmit={(ev) => {
                onSubmit(ev);
              }}
            >
              <FormElement
                name='name'
                onChange={(e) => handleInputChange(e, setName)}
                value={name}
                label={t("name")}
                information={`${t("name")} / ${t("lastName")} / ${t(
                  "businessName"
                )}`}
              />
              <FormElement
                name='cuit'
                onChange={(e) => handleInputChange(e, setCuit)}
                value={cuit}
                label={"CUIT"}
              />
              <FormElement
                name='typePerson'
                onChange={(e) => handleInputChange(e, setTypePerson)}
                value={typePerson}
                label={t("typerPerson")}
                optionsSelect={optionsTypePerson}
                placeholder='Seleccione...'
                typeElement='select'
                controlClasses='select'
              />
              <FormElement
                name='cvu'
                onChange={(e) => handleInputChange(e, setCvu)}
                value={cvu}
                label={t("clientCvu")}
              />
            </Filter>
          </div>

          <ContentHeader
            headingText={t("balanceAccount") + " " + currency + ":"}
            headingSubText={
              <div className='content-balance'>
                <AmountFormat
                  name='balance'
                  value={balanceFormat}
                  displayType='text'
                  allowNegative
                />
                <Link
                  to={{
                    pathname: "/account-virtual-movements",
                  }}
                >
                  <button className='button is-outlined is-small'>
                    {t("showMovement")}
                  </button>
                </Link>
              </div>
            }
            headingRightText={t("totalVirtualAccount")}
            headingRightSubText={`${totalElements}`}
          >
            <ExportExcel onclick={() => callApiList(true)} />
            <TableList
              tableClasses='floating-table'
              totalElements={parseFloat(totalElements)}
              onChangePage={onChangePage}
              forcePage={page}
              head={
                <tr>
                  {/* <th>{t('cvu')}</th> */}
                  {/*<th>{t('alias')}</th> */}
                  <th>CUIT/CUIL</th>
                  <th>
                    <span>
                      {t("name")}
                      <i
                        className='ml-8px fas fa-info-circle has-text-warning'
                        aria-hidden='true'
                        data-for='tooltip-name'
                        data-tip='Test'
                      >
                        <ReactTooltip
                          id='tooltip-name'
                          type='warning'
                          effect='solid'
                        >
                          {`${t("fullName")} / ${t("businessName")}`}
                        </ReactTooltip>
                      </i>
                    </span>
                  </th>
                  <th>{t("fiscalPosition")}</th>
                  <th>{t("CVU")}</th>
                  <th>
                    {t("balance")}
                    <div className='text-small'>{`(${currency})`}</div>
                  </th>
                  <th />
                </tr>
              }
              colNumber={6}
              lenghtData={totalElements !== undefined ? totalElements : 0}
            >
              {list &&
                list.map((row) => (
                  <TransactionRow
                    key={row.id_account}
                    helper={t("detailReport")}
                  >
                    {/*  <td>{row.number_account}</td> */}
                    {/*<td>{row.alias}</td> */}
                    <td>{row.cuit}</td>
                    <td>{`${row.firstname} ${row.lastname ?? ""}`}</td>
                    <td>{row.person_type}</td>
                    <td>
                      {row.cvu === undefined ? `${t("notAvailable")}` : row.cvu}
                    </td>
                    <td>
                      <AmountFormat
                        name='balance'
                        value={row.balance}
                        displayType='text'
                        allowNegative
                      />
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: "/transactions-per-user",
                          state: {
                            email: row.username,
                            identification: row.id_person,
                          },
                        }}
                      >
                        <button className='button is-outlined is-small'>
                          {t("showMovement")}
                        </button>
                      </Link>
                    </td>
                  </TransactionRow>
                ))}
            </TableList>
          </ContentHeader>
        </div>
      </Fragment>
    </div>
  );
};

VirtualAccountBalance.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchListVirtualAccounts: PropTypes.func.isRequired,
  fetchTypePerson: PropTypes.func,
};

VirtualAccountBalance.defaultProps = {
  showMessage: () => {},
  fetchTypePerson: () => {},
};

const mapStateToProps = ({ userSupport, auth, users, dashboard }) => ({
  userSupport,
  auth,
  users,
  dashboard,
});
export default compose(
  connect(mapStateToProps, {
    ...userSupportActions,
    ...authActions,
    ...usersActions,
    ...dashboardActions,
  }),
  withNamespaces()
)(VirtualAccountBalance);
