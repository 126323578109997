import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { initTimer, stopTimer } from "../../util/initTimer";
import { SIZE_PER_PAGE, TOAST_CONFIG } from "../../config/constants";
import {
  AmountFormat,
  ContentHeader,
  DefaultButton,
  ExportExcel,
  Filter,
  FormElement,
  Loading,
  TableList,
  TransactionRow,
} from "../../components";
import "./styles.scss";
import { usersActions } from "../../state/ducks/users";
import { authActions } from "../../state/ducks/auth";
import { Link } from "react-router-dom";
import { formatDate } from "../../util";

const VirtualAccountMovements = (props) => {
  const {
    t,
    fetchVirtualAccountMovements,
    showMessage,
    auth: {
      account: { product: currency },
    },
    users: {
      virtualAccountsMovements: { totalElements, list },
      loading,
    },
  } = props;
  const date = new Date();
  const [page, setPage] = useState(0);
  const [dateStart, setDateStart] = useState(
    new Date(new Date().setDate(date.getDate() - 1))
  );
  const [dateEnd, setDateEnd] = useState(date);

  useEffect(() => {
    stopTimer();
    initTimer(props);
    callApiList();
  }, []);

  useEffect(() => {
    stopTimer();
    initTimer(props);
    callApiList();
  }, [page]);

  const onChangePage = (selectPage) => {
    setPage(selectPage ? selectPage.selected : 0);
  };

  const onChangeDate = (date, set) => {
    stopTimer();
    initTimer(props);
    const dateValue = date || null;
    set(dateValue);
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    callApiList();
  };

  const callApiList = (getExcel = false) => {
    fetchVirtualAccountMovements({
      data: {
        page: getExcel ? 0 : page,
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        export: getExcel,
        date_ini: formatDate(dateStart),
        date_end: formatDate(dateEnd),
      },
      callback: ({ data, success }) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t("connectionError"),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  return (
    <div className='transactions-accounts'>
      {loading && <Loading />}
      <Fragment>
        <div className='wow animated slideInDown'>
          <Filter
            title={t("virtualAccountMovements")}
            subtitle={t("filters")}
            onSubmit={(ev) => {
              onSubmit(ev);
            }}
          >
            <FormElement
              name='dateStart'
              onChange={(ev) => onChangeDate(ev, setDateStart)}
              label={t("createdDateFrom")}
              value={dateStart}
              typeElement='datepicker'
              maxDate={dateEnd || new Date()}
            />
            <FormElement
              name='dateEnd'
              onChange={(ev) => onChangeDate(ev, setDateEnd)}
              value={dateEnd}
              label={t("createdDateUntil")}
              typeElement='datepicker'
              maxDate={new Date()}
              minDate={dateStart}
            />
          </Filter>
        </div>
        <ContentHeader
          headingText={
            <Link
              to={{
                pathname: "/virtual-account-balance",
              }}
            >
              <DefaultButton name={t("goBack")} />
            </Link>
          }
          headingRightText={t("totalTransactions")}
          headingRightSubText={`${totalElements}`}
        >
          <ExportExcel
            title='Export Excel'
            onclick={() => {
              callApiList(true);
            }}
          />
          <TableList
            tableClasses='floating-table'
            totalElements={totalElements}
            onChangePage={onChangePage}
            forcePage={page}
            head={
              <tr>
                <th>{t("dateTransaction")}</th>
                <th>{t("document")}</th>
                <th>{t("cameCode")}</th>
                <th>{t("transactionType")}</th>
                <th>{t("state")}</th>
                <th>{`${t("amount")} (${currency})`}</th>
                <th>{`${t("balanceText")} (${currency})`}</th>
                {/*<th />*/}
              </tr>
            }
            colNumber={8}
            lenghtData={totalElements !== undefined ? totalElements : 0}
          >
            {list &&
              list !== undefined &&
              list.map((row, index) => (
                <TransactionRow key={index} helper={t("detailReport")}>
                  <td>{row.date}</td>
                  <td>{row.document_number}</td>
                  <td>{row.code}</td>
                  <td>{row.type}</td>
                  <td>{t(row.status)}</td>
                  <td>
                    <AmountFormat
                      name='amount'
                      value={row.amount}
                      displayType='text'
                    />
                  </td>
                  <td>
                    <AmountFormat
                      name='balance'
                      value={row.balance}
                      displayType='text'
                    />
                  </td>
                </TransactionRow>
              ))}
          </TableList>
        </ContentHeader>
      </Fragment>
    </div>
  );
};

VirtualAccountMovements.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  clearUsers: PropTypes.func,
  fetchVirtualAccountMovements: PropTypes.func,
  users: PropTypes.shape({
    loading: PropTypes.bool,
    virtualAccountsMovements: PropTypes.shape({
      totalElements: PropTypes.number,
      list: PropTypes.array,
    }),
  }).isRequired,
};

VirtualAccountMovements.defaultProps = {
  showMessage: () => {},
  fetchVirtualAccountMovements: () => {},
  clearUsers: () => {},
};

const mapStateToProps = ({ users, auth }) => ({
  users,
  auth,
});

export default compose(
  connect(mapStateToProps, { ...usersActions, ...authActions }),
  withNamespaces()
)(VirtualAccountMovements);
