import * as fileSaver                           from 'file-saver';
import { userSupportActions, userSupportTypes } from '../ducks/userSupport';

const getTransactionsClaim = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== userSupportTypes.GET_TRANSACTIONS_CLAIM) {
    return;
  }
  const { data: dataSend, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: userSupportTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/claim-transactions',
        data: dataSend,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: userSupportActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Reclamo_transacciones.xlsx');
        } else {
          const rData = responseData.data;
          dispatch(userSupportActions.updateTransactionsClaim({ success: rData.success, data: rData.data }));
          callback({ success: rData.success, data: rData.data });
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: userSupportActions.endFetch,
    },
  });
};

const getTransactionsCommission = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== userSupportTypes.GET_TRANSACTIONS_COMMISSION) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: userSupportTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/commissions-console?page=${data.page}&dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&fullname=${data.name}&came_code=${data.cameCode}&document=${data.document}&pageSize=${data.pageSize}&export=${data.export}&transaction_type=${data.transaction_type}`,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: userSupportActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'transacciones_comisiones.xlsx');
        } else {
          const rData = responseData.data;
          dispatch(userSupportActions.updateTransactionsCommission({ success: rData.success, data: rData.data }));
          callback(responseData);
        }
      },
      onError: (response) => {
        console.log('responseError', response);
        // callback(response);
      },
      onEnd: userSupportActions.endFetch,
    },
  });
};

const getTransactionTypes = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== userSupportTypes.FETCH_TRANSACTION_TYPES) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: userSupportTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/transaction-type-for-commissions'
      },
      authorization: true,
      onStart: userSupportActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(userSupportActions.updateTransactionTypes({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: userSupportActions.endFetch,
    },
  });
}

const getTransactionsLaw = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== userSupportTypes.GET_TRANSACTIONS_LAW) {
    return;
  }
  const { data: dataSend, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: userSupportTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/console/law-credit-debit-report`,
        responseType: exportExcel ? 'blob' : 'json',
        data: dataSend,
      },
      authorization: true,
      onStart: userSupportActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'transacciones_comisiones.xlsx');
        } else {
          const rData = responseData.data;
          dispatch(userSupportActions.updateTransactionsLaw({ success: rData.success, data: rData.data }));
          callback(responseData);
        }
      },
      onError: (response) => {
        console.log('responseError', response);
        // callback(response);
      },
      onEnd: userSupportActions.endFetch,
    },
  });
};

const getTransactionsCommissionIn = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== userSupportTypes.GET_TRANSACTIONS_COMMISSION_IN) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: userSupportTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/console/charge-in-report`,
        responseType: exportExcel ? 'blob' : 'json',
        data,
      },
      authorization: true,
      onStart: userSupportActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'transacciones_comisiones.xlsx');
        } else {
          const rData = responseData.data;
          dispatch(userSupportActions.updateTransactionsCommissionIn({ success: rData.success, data: rData.data }));
          callback(responseData);
        }
      },
      onError: (response) => {
        console.log('responseError', response);
        // callback(response);
      },
      onEnd: userSupportActions.endFetch,
    },
  });
};

const getTransactionsCommissionLot = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== userSupportTypes.GET_TRANSACTIONS_COMMISSION_LOT) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: userSupportTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/console/charge-in-lot-report`,
        responseType: exportExcel ? 'blob' : 'json',
        data,
      },
      authorization: true,
      onStart: userSupportActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'transacciones_comisiones.xlsx');
        } else {
          const rData = responseData.data;
          dispatch(userSupportActions.updateTransactionsCommissionLot({ success: rData.success, data: rData.data }));
          callback(responseData);
        }
      },
      onError: (response) => {
        console.log('responseError', response);
        // callback(response);
      },
      onEnd: userSupportActions.endFetch,
    },
  });
};

const getTransportCompanyLot = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== userSupportTypes.FETCH_TRANSPORT_COMPANY_LOT) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: userSupportTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/initial-charge-in-lot'
      },
      authorization: true,
      onStart: userSupportActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(userSupportActions.updateTransportCompanyLot({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: userSupportActions.endFetch,
    },
  });
}

export default [
  getTransactionsClaim,
  getTransactionsCommission,
  getTransactionTypes,
  getTransactionsLaw,
  getTransactionsCommissionIn,
  getTransactionsCommissionLot,
  getTransportCompanyLot,
];
