import { createAction } from 'redux-actions';
import types            from './types';

const clearUsers = createAction(types.CLEAR);
const clearUserDetail = createAction(types.CLEAR_USER);
const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchUsers = createAction(types.FETCH_USERS);
const fetchUsersData = createAction(types.FETCH_USERS_DATA);
const detailUser = createAction(types.FETCH_USERS_DETAIL);
const detailUserData = createAction(types.FETCH_USERS_DETAIL_DATA);
const dataFiscal = createAction(types.FETCH_USER_DATA_FISCAL);
const dataFiscalData = createAction(types.GET_USER_DATA_FISCAL);
const editDataFiscal = createAction(types.EDIT_USER_DATA_FISCAL);
const updateDataFiscal = createAction(types.SET_USER_DATA_FISCAL);
const fetchStatusIva = createAction(types.FETCH_STATUS_IVA);
const setStatusIva = createAction(types.SET_STATUS_IVA);
const fetchTypePerson = createAction(types.FETCH_TYPE_PERSON);
const setTypePerson = createAction(types.SET_TYPE_PERSON);
const fetchListVirtualAccounts = createAction(types.FETCH_LIST_VIRTUAL_ACCOUNTS);
const setListVirtualAccounts = createAction(types.SET_LIST_VIRTUAL_ACCOUNTS);
const editDataUser = createAction(types.EDIT_DATA_USER);
const updateDataUser = createAction(types.SET_USER_DATA);
const editDataAliasUser = createAction(types.EDIT_DATA_ALIAS_USER);
const updateDataAliasUser = createAction(types.SET_USER_ALIAS_DATA);
const fetchTransactionsPerUser = createAction(types.FETCH_TRANSACTIONS_PER_USER);
const updateTransactionsPerUser = createAction(types.UPDATE_TRANSACTIONS_PER_USER);
const fetchAllUsers = createAction(types.FETCH_ALL_USERS);
const fetchAllUsersData = createAction(types.FETCH_ALL_USERS_DATA);
const fetchListAllVirtualAccounts = createAction(types.FETCH_LIST_ALL_VIRTUAL_ACCOUNTS);
const setListAllVirtualAccounts = createAction(types.SET_LIST_ALL_VIRTUAL_ACCOUNTS);
const fetchListUserExcel = createAction(types.FETCH_LIST_USER_EXCEL);
const fetchListStatusUser = createAction(types.FETCH_LIST_STATUS_USER);
const updateListStatusUser = createAction(types.UPDATE_LIST_STATUS_USER);
const editAddressUser = createAction(types.EDIT_ADDRESS_USER);
const updateAddressUser = createAction(types.SET_USER_ADDRESS);
const editEntitiesUser = createAction(types.EDIT_ENTITIES_USER);
const updateEntitiesUser = createAction(types.SET_ENTITIES_USER);
const fetchUsersOperator = createAction(types.FETCH_USERS_OPERATOR);
const setUsersOperator = createAction(types.SET_USERS_OPERATOR);
const newUserOperator = createAction(types.NEW_USER_OPERATOR);
const updateStatusUser = createAction(types.UPDATE_STATUS_USER);
const assignedRemoveDriver = createAction(types.ASIGNED_REMOVE_DRIVER);
const fetchListOperators = createAction(types.FETCH_LIST_OPERATORS);
const updateListOperators = createAction(types.UPDATE_LIST_OPERATORS);
const fetchRolOperator = createAction(types.FETCH_ROL_OPERATOR);
const updateRolOperator = createAction(types.UPDATE_ROL_OPERATOR);
const updateStateOperator = createAction(types.UPDATE_STATE_OPERATOR);
const fetchOperatorAgency = createAction(types.FETCH_OPERATOR_AGENCY);
const updateOperatorAgency = createAction(types.UPDATE_OPERATOR_AGENCY);
const fetchStatusFilter = createAction(types.FETCH_STATUS_FILTER);
const updateStatusFilter = createAction(types.UPDATE_STATUS_FILTER);
const fetchProvince = createAction(types.FETCH_PROVINCE);
const updateProvince = createAction(types.UPDATE_PROVINCE);
const fetchLocations = createAction(types.FETCH_LOCATIONS);
const updateLocations = createAction(types.UPDATE_LOCATIONS);
const fetchResetPassOperator = createAction(types.FETCH_RESET_PASS_OPERATOR);
const fetchUploadDocuments = createAction(types.FETCH_UPLOAD_DOCUMENTS);
const updateUploadDocuments = createAction(types.UPDATE_UPLOAD_DOCUMENTS);
const fetchImg = createAction(types.FETCH_IMG);
const fetchPdf = createAction(types.FETCH_PDF);
const fetchAccountsState = createAction(types.FETCH_ACCOUNTS_STATE);
const updateAccountsState = createAction(types.UPDATE_ACCOUNTS_STATE);
const chargesLaw = createAction(types.CHARGES_LEY);
const updateChargesLaw = createAction(types.UPDATE_CHARGES_LEY);
const fetchVirtualAccountMovements = createAction(types.FETCH_VIRTUAL_ACCOUNTS_MOVEMENTS);
const updateVirtualAccountMovements = createAction(types.UPDATE_VIRTUAL_ACCOUNTS_MOVEMENTS);
const fetchRubrosAfip = createAction(types.FETCH_RUBROS_AFIP);
const setRubrosAfip = createAction(types.SET_RUBROS_AFIP);
const updateAfipData = createAction(types.UPDATE_AFIP_DATA);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchUsers,
  fetchUsersData,
  detailUser,
  detailUserData,
  dataFiscal,
  dataFiscalData,
  editDataFiscal,
  updateDataFiscal,
  fetchStatusIva,
  setStatusIva,
  fetchTypePerson,
  setTypePerson,
  fetchListVirtualAccounts,
  setListVirtualAccounts,
  editDataUser,
  updateDataUser,
  editDataAliasUser,
  updateDataAliasUser,
  fetchAllUsers,
  fetchAllUsersData,
  fetchListAllVirtualAccounts,
  setListAllVirtualAccounts,
  fetchListStatusUser,
  updateListStatusUser,
  fetchListUserExcel,
  fetchTransactionsPerUser,
  updateTransactionsPerUser,
  clearUsers,
  clearUserDetail,
  updateAddressUser,
  editAddressUser,
  editEntitiesUser,
  updateEntitiesUser,
  fetchUsersOperator,
  setUsersOperator,
  newUserOperator,
  updateStatusUser,
  assignedRemoveDriver,
  fetchListOperators,
  updateListOperators,
  fetchRolOperator,
  updateRolOperator,
  updateStateOperator,
  fetchOperatorAgency,
  updateOperatorAgency,
  fetchStatusFilter,
  updateStatusFilter,
  fetchProvince,
  updateProvince,
  fetchLocations,
  updateLocations,
  fetchResetPassOperator,
  fetchUploadDocuments,
  updateUploadDocuments,
  fetchImg,
  fetchPdf,
  fetchAccountsState,
  updateAccountsState,
  chargesLaw,
  updateChargesLaw,
  fetchVirtualAccountMovements,
  updateVirtualAccountMovements,
  fetchRubrosAfip,
  setRubrosAfip,
  updateAfipData,
};
