import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const getTransactionsClaim = createAction(types.GET_TRANSACTIONS_CLAIM);
const updateTransactionsClaim = createAction(types.UPDATE_TRANSACTIONS_CLAIM);
const updateTransactionsClaimExcel = createAction(types.UPDATE_TRANSACTIONS_CLAIM_EXCEL);
const getTransactionsCommission = createAction(types.GET_TRANSACTIONS_COMMISSION);
const updateTransactionsCommission = createAction(types.UPDATE_TRANSACTIONS_COMMISSION);
const updateTransactionsCommissionExcel = createAction(types.UPDATE_TRANSACTIONS_COMMISSION_EXCEL);
const getTransactionTypes = createAction(types.FETCH_TRANSACTION_TYPES);
const updateTransactionTypes = createAction(types.UPDATE_TRANSACTION_TYPES);
const getTransactionsLaw = createAction(types.GET_TRANSACTIONS_LAW);
const updateTransactionsLaw = createAction(types.UPDATE_TRANSACTIONS_LAW);
const getTransactionsCommissionIn = createAction(types.GET_TRANSACTIONS_COMMISSION_IN);
const updateTransactionsCommissionIn = createAction(types.UPDATE_TRANSACTIONS_COMMISSION_IN);
const getTransactionsCommissionLot = createAction(types.GET_TRANSACTIONS_COMMISSION_LOT);
const updateTransactionsCommissionLot = createAction(types.UPDATE_TRANSACTIONS_COMMISSION_LOT);
const getTransportCompanyLot = createAction(types.FETCH_TRANSPORT_COMPANY_LOT);
const updateTransportCompanyLot = createAction(types.UPDATE_TRANSPORT_COMPANY_LOT);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  getTransactionsClaim,
  updateTransactionsClaim,
  getTransactionsCommission,
  updateTransactionsCommission,
  updateTransactionsCommissionExcel,
  updateTransactionsClaimExcel,
  getTransactionTypes,
  updateTransactionTypes,
  getTransactionsLaw,
  updateTransactionsLaw,
  getTransactionsCommissionIn,
  updateTransactionsCommissionIn,
  getTransactionsCommissionLot,
  updateTransactionsCommissionLot,
  getTransportCompanyLot,
  updateTransportCompanyLot,
};
