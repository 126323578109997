import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { authActions } from "../../state/ducks/auth";
import { usersActions } from "../../state/ducks/users";
import { withNamespaces } from "react-i18next";
import { initTimer, stopTimer } from "../../util/initTimer";
import {
  ContentHeader,
  Filter,
  FormElement,
  Loading,
  PrimaryButton,
  Switch,
  TableList,
  TransactionRow,
} from "../../components";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../config/constants";
import { FormUserOperator } from "./components";
import "./styles.scss";

class UsersOperators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "",
      loading: false,
      email: "",
      lastname: "",
      document_number_aux: "",
      document_type: "DNI",
      document_type_aux: "CUIL/CUIT",
      username: "",
      cuit: "",
      page: 0,
      switchList: [],
      name: "",
      status: "",
      document_number: "",
      tableLoading: true
    };

    this.callApiList = this.callApiList.bind(this);
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);

    const { fetchListStatusUser } = this.props;
    fetchListStatusUser();
    this.callApiList();
  }

  callApiList = () => {
    const { t, showMessage, fetchUsersOperator } = this.props;
    const { name, status, document_number } = this.state;
    this.setState({
      tableLoading:true
    })

    fetchUsersOperator({
      data: {
        name,
        status,
        document_number,
      },
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t("connectionError"),
            config: TOAST_CONFIG.ERROR,
          });
        }
        this.setState({
          tableLoading: false
        })
      },
    });
  };

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const {
      target: { value: targetValue, name },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  handleChangeView = (view = "") => {
    this.setState({ view });
    if (view === "") {
      this.callApiList();
    }
  };

  handleEdit = (
    id,
    date,
    name,
    lastname,
    email,
    document_type,
    document_number,
    document_number_aux,
    document_type_aux
  ) => {
    this.setState({
      id,
      date,
      name,
      lastname,
      email,
      document_type,
      document_number,
      document_number_aux,
      document_type_aux,
      view: "edit",
    });
  };

  handleActiveDisable = (event, i, id_user, is_system) => {
    const { t, showMessage, updateStatusUser } = this.props;
    if (is_system) {
      toast.dismiss();
      showMessage({
        message: t("forbiddenChange"),
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }
    const isChecked = event.target.checked;

    const data = {
      id_user: id_user,
      new_status: !isChecked ? 0 : 1,
    };

    const status = isChecked ? "activo" : "desactivó";

    updateStatusUser({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          const { switchList } = this.state;
          switchList[i] = isChecked;
          this.setState({ switchList });
          showMessage({
            message: t("activeDisableUSerSuccess", { status }),
            config:
              status === "activó" ? TOAST_CONFIG.SUCCESS : TOAST_CONFIG.INFO,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t("saveUserDataError"),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };
  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(
      () => ({
        page: selectPage ? selectPage.selected : 0,
      }),
      () => {
        this.callApiList();
      }
    );
  };
  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };

  render() {
    const {
      t,
      users: {
        loading,
        list_users_operator: { list, totalElements },
        list_status_user,
      },
    } = this.props;
    const { name, user_status, document_number, switchList, view, tableLoading } = this.state;
   
    if (view === "create") {
      return <FormUserOperator handleChangeView={this.handleChangeView} />;
    }

    return (
      <div className='list-operators'>
        {loading && <Loading />}
        <div className='wow animated slideInDown'>
          <Filter
            title={t("userOperator")}
            subtitle={t("filters")}
            onSubmit={(ev) => {
              this.onSubmit(ev);
            }}
          >
            <FormElement
              name='name'
              onChange={this.handleInputChange}
              value={name}
              label={t("name")}
            />
            <FormElement
              name='document_number'
              onChange={this.handleInputChange}
              value={document_number}
              label={"CUIT"}
            />
            <FormElement
              name='status'
              onChange={this.handleInputChange}
              value={user_status}
              label={t("userState")}
              optionsSelect={list_status_user}
              placeholder='Seleccione...'
              typeElement='select'
              controlClasses='select'
            />
          </Filter>
          <div className='button-new-operator'>
            <PrimaryButton
              name={t("newUserOperator")}
              onClick={() => {
                this.handleChangeView("create");
              }}
            />
          </div>
        </div>
        <ContentHeader
          headingText=''
          headingRightText={t("operatorsTotal")}
          headingRightSubText={`${totalElements}`}
          classFirstColumn='is-paddingless'
        >
          <TableList
            tableClasses='floating-table'
            totalElements={totalElements}
            onChangePage={this.onChangePage}
            hidePagination={true}
            head={
              <tr>
                <th>{t("createdDate")}</th>
                <th>{t("username")}</th>
                <th>{t("nameCuit")}</th>
                <th>{t("name")}</th>
                <th>{t("userState")}</th>
                <th />
              </tr>
            }
            colNumber={6}
            lenghtData={totalElements}
            loading={tableLoading}
          >
            {list !== undefined &&
              list.map((user, i) => (
                <TransactionRow key={i} helper={t("detailRate")}>
                  <td>{user.date}</td>
                  <td>{user.username}</td>
                  <td>{user.document_number}</td>
                  <td>{`${user.name} ${
                    user.lastname ? ` ${user.lastname}` : ""
                  }`}</td>
                  <td>
                    {switchList[i] !== undefined
                      ? switchList[i] === true
                        ? t("active")
                        : t("inactive")
                      : user.user_status === "1"
                      ? t("active")
                      : t("inactive")}{" "}
                  </td>
                  {/*   <td>
                    <button
                      type="button"
                      className="button is-info is-inverted"
                      onClick={() => { this.handleEdit(user.id_person, user.date, user.name, user.lastname, user.username, user.document_type, user.document_number, user.document_number_aux, user.document_type_aux); }}

                      data-for="tooltip-edit"
                      data-tip=""
                    >
                      <span className="icon">
                        <i className="far fa-edit">
                          <ReactTooltip
                            id="tooltip-edit"
                            type="info"
                            effect="solid"
                          >
                            {t('edit')}
                          </ReactTooltip>
                        </i>
                      </span>
                    </button>
                </td> */}
                  <td>
                    <Switch
                      name={`switch-${i}`}
                      id={`switch-orange-${i}`}
                      value={
                        switchList[i] !== undefined
                          ? switchList[i]
                          : user.user_status === "1"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.handleActiveDisable(
                          event,
                          i,
                          user.id_user,
                          user.is_system
                        );
                      }}
                      checked={
                        switchList[i] !== undefined
                          ? switchList[i]
                          : user.user_status === "1"
                          ? true
                          : false
                      }
                      htmlFor={`switch-orange-${i}`}
                    />
                  </td>
                </TransactionRow>
              ))}
          </TableList>
        </ContentHeader>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, users }) => ({
  auth,
  users,
});

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
  }),
  withNamespaces()
)(UsersOperators);
