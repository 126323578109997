const START_FETCH = 'userSupport/START_FETCH';
const END_FETCH = 'userSupport/END_FETCH';
const END_ERROR = 'userSupport/END_ERROR';
const API_CALL = 'userSupport/API_CALL';
const MESSAGE = 'userSupport/MESSAGE';
const GET_TRANSACTIONS_CLAIM = 'userSupport/GET_TRANSACTIONS_CLAIM';
const UPDATE_TRANSACTIONS_CLAIM = 'userSupport/UPDATE_TRANSACTIONS_CLAIM';
const UPDATE_TRANSACTIONS_CLAIM_EXCEL = 'userSupport/UPDATE_TRANSACTIONS_CLAIM_EXCEL';
const GET_TRANSACTIONS_COMMISSION = 'userSupport/GET_TRANSACTIONS_COMMISSION';
const UPDATE_TRANSACTIONS_COMMISSION = 'userSupport/UPDATE_TRANSACTIONS_COMMISSION';
const UPDATE_TRANSACTIONS_COMMISSION_EXCEL = 'userSupport/UPDATE_TRANSACTIONS_COMMISSION_EXCEL';
const FETCH_TRANSACTION_TYPES = 'userSupport/GET_TRANSACTION_TYPES';
const UPDATE_TRANSACTION_TYPES = 'userSupport/UPDATE_TRANSACTION_TYPES';
const GET_TRANSACTIONS_LAW = 'userSupport/GET_TRANSACTIONS_LAW';
const UPDATE_TRANSACTIONS_LAW = 'userSupport/UPDATE_TRANSACTIONS_LAW';
const GET_TRANSACTIONS_COMMISSION_IN = 'userSupport/GET_TRANSACTIONS_COMMISSION_IN';
const UPDATE_TRANSACTIONS_COMMISSION_IN = 'userSupport/UPDATE_TRANSACTIONS_COMMISSION_IN';
const GET_TRANSACTIONS_COMMISSION_LOT = 'userSupport/GET_TRANSACTIONS_COMMISSION_LOT';
const UPDATE_TRANSACTIONS_COMMISSION_LOT = 'userSupport/UPDATE_TRANSACTIONS_COMMISSION_LOT';
const FETCH_TRANSPORT_COMPANY_LOT = 'userSupport/FETCH_TRANSPORT_COMPANY_LOT';
const UPDATE_TRANSPORT_COMPANY_LOT = 'userSupport/UPDATE_TRANSPORT_COMPANY_LOT';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  GET_TRANSACTIONS_CLAIM,
  UPDATE_TRANSACTIONS_CLAIM,
  GET_TRANSACTIONS_COMMISSION,
  UPDATE_TRANSACTIONS_COMMISSION,
  UPDATE_TRANSACTIONS_COMMISSION_EXCEL,
  UPDATE_TRANSACTIONS_CLAIM_EXCEL,
  FETCH_TRANSACTION_TYPES,
  UPDATE_TRANSACTION_TYPES,
  GET_TRANSACTIONS_LAW,
  UPDATE_TRANSACTIONS_LAW,
  GET_TRANSACTIONS_COMMISSION_IN,
  UPDATE_TRANSACTIONS_COMMISSION_IN,
  GET_TRANSACTIONS_COMMISSION_LOT,
  UPDATE_TRANSACTIONS_COMMISSION_LOT,
  FETCH_TRANSPORT_COMPANY_LOT,
  UPDATE_TRANSPORT_COMPANY_LOT,
};
