import React, { Component }                           from 'react';
import PropTypes                                                from 'prop-types';
import { compose }                                              from 'redux';
import { connect }                                              from 'react-redux';
import { withNamespaces }                                       from 'react-i18next';
import { initTimer, stopTimer }                                 from '../../util/initTimer';
import { Form,  DefaultButton, ContentHeader, Tabs } from '../../components';
import { AddressDetailForm, CompanyDetailForm, UserDetailForm } from '../userDetail';
import { usersActions }                                         from '../../state/ducks/users';
import { authActions }                                          from '../../state/ducks/auth';
import { agencyActions }                                        from '../../state/ducks/agency';
import { TOAST_CONFIG }                                         from "../../config/constants";

class TransportCompanyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'first',
      country: 'AR',
      dni: '',
      cuit: '',
      name: '',
      lastname: '',
      phoneNumber: '',
      cuitCompany: '',
      companyType: '',
      nameCompany: '',
      iva: '',
      usernameCompany: '',
      phoneNumberCompany: '',
      province: '',
      location: '',
      city: '',
      address: '',
      zipCode: '',
      finished: false,
      listTabs: [],
    };
  }

  componentDidMount() {
    const {
      clearUserDetail,
      fetchCompanyTypes,
      getProvinces,
      fetchRubrosAfip,
    } = this.props;
    stopTimer();
    initTimer(this.props);
    clearUserDetail();
    fetchCompanyTypes();
    getProvinces();
    fetchRubrosAfip();
    this.handleCreateTabs();
  }

  handleCreateTabs = () => {
    const {
      view,
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      country,
      province,
      location,
      city,
      address,
      zipCode,
    } = this.state;
    const person = {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
    };
    const company = {
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
    };
    const fullAddress = {
      country,
      province,
      location,
      city,
      address,
      zipCode,
    };
    const {
      t, users: { loading },
      auth: { loading: loadingAuth, },
      agency: { loading: loadingAgency, },
    } = this.props;

    let { children, submitText } = ''

    const listTabs = [
      {
        title: 'transportCompanyData',
        id: 't1',
        content: (
          <div className="pr-16px pl-16px pt-16px content-xl-center">
            <Form
              submitText={t('save')}
              nameButton="configButton"
              onSubmit={(e) => {}}
            >
              <CompanyDetailForm
                handleInputChange={this.handleInputChange}
                handlePhoneChange={this.handlePhoneChange}
                company={company}
                titleLabel={'transportCompanyData'}
                editable
                hiddeIva = {true}
                hiddeTypeCompany={true}
              />
            </Form>
          </div>
        ),
      },
      {
        title: 'legalRepresentant',
        id: 't2',
        content: (
          <div className="pr-16px pl-16px pt-16px content-xl-center">
            <Form
              submitText={t('save')}
              nameButton="configButton"
              onSubmit={(e) => {}}
            >
              <UserDetailForm
                handleInputChange={this.handleInputChange}
                handlePhoneChange={this.handlePhoneChange}
                handleVerifyCuit = {this.handleVerifyCuit}
                person={person}
                editable
                hideEmail={true}
              />
            </Form>
          </div>
        ),
      },
      {
        title: 'detailAddress',
        id: 't3',
        content: (
          <div className="pr-16px pl-16px pt-16px content-xl-center">
            <Form
              submitText={t('save')}
              nameButton="configButton"
              onSubmit={(e) => {}}
            >
              <AddressDetailForm
                handleInputChange={this.handleInputChange}
                handlePhoneChange={this.handlePhoneChange}
                handleInputChangeZipCode={this.handleInputChangeZipCode}
                address={fullAddress}
                titleLabel={'addressBusinessInformation'}
                editable={false}
              />
            </Form>
          </div>
        ),
      },
    ];
    this.setState({ listTabs });
  }

  callApiList = () => {
    // const { fetchCreateAgency } = this.props;
    // const {
    //   dni,
    //   cuit,
    //   name,
    //   lastname,
    //   phoneNumber,
    //   cuitCompany,
    //   companyType,
    //   nameCompany,
    //   iva,
    //   usernameCompany,
    //   phoneNumberCompany,
    //   city: { value: id_city },
    //   address,
    //   zipCode,
    // } = this.state;
    //
    // fetchCreateAgency({
    //   data: {
    //     business_cuit: cuitCompany,
    //     business_name: nameCompany,
    //     email: usernameCompany,
    //     iva,
    //     phone_number: phoneNumberCompany,
    //
    //     address,
    //     id_city,
    //     zip_code: zipCode,
    //
    //     name: name,
    //     lastname: lastname,
    //     document_number: dni,
    //     document_number_aux: cuit,
    //     agent_phone_number: phoneNumber
    //   },
    //   callback: ({ success }) => {
    //     if (success) {
    //       this.setState({
    //         finished: true
    //       });
    //     }
    //   }
    // });
    alert('Datos editados')
  }

  handlePhoneChange = (value, name) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: targetValue,
    });
  };

  handleInputChangeZipCode = ({ target: { value: targetValue, name } }) => {
    const regex = new RegExp('^[a-zA-Z0-9]+$');
    if (targetValue === "" || regex.test(targetValue)) {
      this.setState({
        [name]: targetValue,
      });
    }
  }

  handleVerifyCuit = (ev) => {
    this.setState({
      dni: ev.target.value.substr(2, 8)
        .replace(/^0+/, ''),
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  handleChangeView = (event, view = 'first') => {
    const { t, showMessage } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      province,
      location,
      city,
      address,
      zipCode,
    } = this.state;
    if (dni === ''
      || cuit === ''
      || name === ''
      || lastname === ''
      || phoneNumber === ''
      || cuitCompany === ''
      || companyType === ''
      || nameCompany === ''
      || iva === ''
      || usernameCompany === ''
      || phoneNumberCompany === ''
      || province === ''
      || location === ''
      || city === ''
      || address === ''
      || zipCode === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }
    this.setState({ view });
  }

  render() {
    const { listTabs } = this.state;
    const { t, handleReturn } = this.props;
    return (
      <div>
        {/*{(loading || loadingAuth) && <Loading />}*/}
        <ContentHeader
          headingText={(
            <DefaultButton
              name={t('goBack')}
              onClick={handleReturn}
            />
          )}
        >
          <Tabs listTabs={listTabs} />
        </ContentHeader>
      </div>
    );
  }
}

TransportCompanyEdit.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(TransportCompanyEdit);
