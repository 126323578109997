import React, { Component }                                      from 'react';
import PropTypes                                                 from 'prop-types';
import { compose }                                               from 'redux';
import { connect }                                               from 'react-redux';
import { withNamespaces }                                        from 'react-i18next';
import { toast }                                                 from 'react-toastify';
import { initTimer, stopTimer }                                  from '../../../../util/initTimer';
import { Form }                                                  from '../../../../components';
import { CompanyDetailForm, OperatorDetailForm, UserDetailForm } from '../../../userDetail';
import { usersActions }                                          from '../../../../state/ducks/users';
import { authActions }                                           from '../../../../state/ducks/auth';
import { agencyActions }                                         from '../../../../state/ducks/agency';
import { TOAST_CONFIG }                                          from '../../../../config/constants';
import { checkPhoneNumber }                                      from '../../../../util';
import { ActionButtons }                                         from "../index";


class UserData extends Component {
  constructor(props) {
    super(props);
    const {
      users: {
        userDetail: {
          active,
          cuit,
          document_number,
          IVA: iva,
          phone_number:  phoneNumber,
          country_code,
          name,
          lastname,
          username,
          id_type_person,
          responsible,
          id_rol_type,
          type,
          id_agency
        }
      }
    } = props;
    if (type === 'Operador' || type === 'Administrador de agencia') {
      this.state = {
        dni: document_number,
        cuit,
        name,
        lastname,
        phoneNumber: country_code + phoneNumber,
        idAgency: id_agency,
        email: username,
        rol: id_rol_type,
        titleSectionUser: 'editOperatorForm',
      };
    }
    if (type === 'Pasajero') {
      this.state = {
        dni: document_number,
        cuit,
        name,
        lastname,
        phoneNumber: country_code + phoneNumber,
        email: username,
        rol: id_rol_type,
        titleSectionUser: 'passengerData',
      };
    } else if (responsible) {
      const {
        name: nameResponsible,
        lastname: lastNameResponsible,
        document_number: dniResponsible,
        document_number_aux: cuitResponsible,
        phone_number_resp: phoneResponsible,
        country_code_resp: countryCodeResponsible,
      } = responsible;
      this.state = {
        dni: dniResponsible,
        cuit: cuitResponsible,
        name: nameResponsible,
        lastname: lastNameResponsible,
        phoneNumber: countryCodeResponsible + phoneResponsible,
        cuitCompany: cuit,
        companyType: id_type_person,
        nameCompany: name,
        iva,
        usernameCompany: username,
        phoneNumberCompany: country_code + phoneNumber,
      };
      if (type === 'Agencia') {
        this.state = {
          ...this.state,
          titleSectionUser: 'legalRepresentant',
          titleSectionCompany: 'agencyData',
        };
      } else {
        this.state = {
          ...this.state,
          titleSectionUser: 'legalRepresentant',
          titleSectionCompany: 'transportCompanyData',
        };
      }
    }
    this.state = {
      ...this.state,
      country: 'AR',
      type,
      statusUser: active,
    };
  }

  componentDidMount() {
    const {
      fetchCompanyTypes,
      fetchListSimpleAgencies,
      fetchListRoles,
    } = this.props;

    stopTimer();
    initTimer(this.props);
    fetchListRoles();
    fetchListSimpleAgencies();
    fetchCompanyTypes();
  }

  callApiList = async (e) => {
    e.preventDefault();
    const {
      name,
      lastname,
      phoneNumber,
      email,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      statusUser,
      type,
      rol,
      dni,
      cuit,
    } = this.state;
    const { showMessage, editDataUser, t, identification } = this.props;

    const data = {
      name,
      last_name: lastname,
      email: usernameCompany || email,
      person: identification,
      active: statusUser,
      birthday: "",
      gender: "",
      phone_number_business: checkPhoneNumber(phoneNumberCompany),
      phone_number: checkPhoneNumber(phoneNumber),
      iva,
      id_person_type: companyType,
      business_name: nameCompany,
    };

    if (type === 'Operador' || type === 'Administrador de agencia') {
      data.rol = rol;
    }

    if(type === 'Empresa de Transporte'){
      data.document_number = dni;
      data.document_number_aux = cuit;
    }

    editDataUser({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('saveUserData'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: response.data.message ?? t('saveUserDataError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  handlePhoneChange = (value, name) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = ({ target: { checked, value: targetValue, type, name } }) => {
    const value = type === 'checkbox' ? (checked ? '1' : '0') : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleVerifyCuit = (ev) => {
    this.setState({
      dni: ev.target.value.substr(2, 8)
        .replace(/^0+/, ''),
    });
  };

  handleValidator = () => {
    const { t, showMessage } = this.props;
    const {
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      rol,
      type,
    } = this.state;
    if (type === 'Operador' || type === 'Administrador de agencia') {
      if (name === ''
        || lastname === ''
        || phoneNumber === ''
        || rol === '') {
        showMessage({
          message: t('allRequiredFields'),
          config: TOAST_CONFIG.ERROR,
        });
        return false;
      }
    } else if (type === 'Agencia') {
      if (name === ''
        || lastname === ''
        || phoneNumber === ''
        || cuitCompany === ''
        || companyType === ''
        || nameCompany === ''
        || iva === ''
        || usernameCompany === ''
        || phoneNumberCompany === '') {
        showMessage({
          message: t('allRequiredFields'),
          config: TOAST_CONFIG.ERROR,
        });
        return false;
      }
    } else {
      if (name === ''
        || lastname === ''
        || phoneNumber === ''
        || cuitCompany === ''
        || nameCompany === ''
        || usernameCompany === ''
        || phoneNumberCompany === '') {
        showMessage({
          message: t('allRequiredFields'),
          config: TOAST_CONFIG.ERROR,
        });
        return false;
      }
    }
    return true;
  }

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    if (this.handleValidator()) {
      this.callApiList(event);
    }
  };

  render() {
    const { identification } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      email,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      idAgency,
      rol,
      type,
      titleSectionUser,
      titleSectionCompany,
      statusUser,
    } = this.state;
    const operator = {
      idAgency,
      rol,
    };
    const person = {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      email,
    };
    const company = {
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
    };

    const { t } = this.props;

    return (
      <div className="pr-16px pl-16px pt-16px content-xl-center">
        <Form
          submitText={t('save')}
          nameButton="configButton"
          onSubmit={(e) => {this.onSubmit(e)}}
        >
          {(type === 'Agencia') && (
            <CompanyDetailForm
              handleInputChange={this.handleInputChange}
              handlePhoneChange={this.handlePhoneChange}
              company={company}
              titleLabel={titleSectionCompany}
              cuitText
              editable
            />
          )}
          {(type === 'Empresa de Transporte') && (
            <CompanyDetailForm
              handleInputChange={this.handleInputChange}
              handlePhoneChange={this.handlePhoneChange}
              company={company}
              titleLabel={titleSectionCompany}
              cuitText
              editable
              hiddeIva
              hiddeTypeCompany
            />
          )}

          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            person={person}
            title={titleSectionUser}
            cuitText={titleSectionUser === 'legalRepresentant' ? false : true}
            dniText
            hideEmail={(type === 'Agencia' || type === 'Empresa de Transporte')}
            editable
            handleVerifyCuit={this.handleVerifyCuit}
          />
          {(type === 'Operador' || type === 'Administrador de agencia') && (
            <OperatorDetailForm
              handleInputChange={this.handleInputChange}
              handlePhoneChange={this.handlePhoneChange}
              operator={operator}
              agencyText
              editable
            />
          )}
          <ActionButtons
            identification={identification}
            handleInputChange={this.handleInputChange}
            statusUser={statusUser}
          />
        </Form>
      </div>
    );
  }
}

UserData.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(UserData);
