import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { transactionsActions }        from '../../state/ducks/transactions';
import { userSupportActions }         from '../../state/ducks/userSupport';
import {
  AmountFormat,
  ContentHeader, DefaultButton,
  ExportExcel,
  Filter,
  FormElement,
  Loading,
  TableList,
  TransactionRow,
} from '../../components';
import {
  initTimer,
  stopTimer,
}                                     from '../../util/initTimer';

import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../config/constants';
import { authActions }                 from '../../state/ducks/auth';
import { formatDate }                  from '../../util';
import './styles.scss';
import {Link} from "react-router-dom";

class transactionsCommissionsIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      id_lot: '',
      person_type: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.callApiList = this.callApiList.bind(this);
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    const { location: { state } } = this.props;
    if (state !== undefined) {
      const { id_lot, person_type } = state;
      if (id_lot !== undefined) {
        this.setState({ id_lot: id_lot, person_type: person_type }, () => {this.callApiList()});
      }
    }
  }

  onChangePage = (selectPage) => {
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  callApiList = (getExcel = false) => {
    const {
      t, getTransactionsCommissionIn, showMessage, userSupport: {
        transactionsLaw: { totalElements }
      }
    } = this.props;
    const {page, id_lot} = this.state;

    getTransactionsCommissionIn({
      data: {
        page: getExcel ? 0 : page,
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        export: getExcel,
        id_lot,

      },
      callback: (data) => {
        console.log('data---',data)
        const { success } = data.data;
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };


  render() {
    const {
      t,
      userSupport: {
        transactionsCommissionIn: { totalElements, list, lot },
        loading,
      }, auth: { account: { product: currency } },
    } = this.props;


    const {page, person_type} = this.state;
    return (
      <div className="transaction-lot">
        {loading && <Loading />}
        <Fragment>
          <div className="wow animated slideInDown">
            <Filter
              title={t('transactionsLot')}
            />
          </div>
          <ContentHeader
            headingRightText={t('totalTransactions')}
            headingRightSubText={totalElements}
          >
            <Link to={{
              pathname: '/transactions-commissions-lot',
              state: { person_type : person_type },
            }}
            >
              <DefaultButton name={t('goBack')}  />
            </Link>
            {/* <ExportExcel
              onclick={() => { this.callApiList(true); }}
            /> */}

            <div className="columns is-centered " style={{ animationDelay: '1s' }}>
              <div className="column is-full wow animated slideInDown">
                <hr className="dots" />
              </div>
            </div>
            <div className="columns is-centered is-multiline">
              <div className="column wow animated slideInDown info-lot">
                  <div className="label">{t('amountLot')}</div>
                  <div className="value">
                    <AmountFormat
                      name="total"
                      value={lot.amount}
                      displayType="text"
                    />
                  </div>
                </div>

              <div className="column wow animated slideInDown info-lot">
                  <div className="label" >{t('amountLiquidate')}</div>
                  <div className="value">
                    <AmountFormat
                      name="total"
                      value={lot.accreditation}
                      displayType="text"
                    />
                  </div>
                </div>
            </div>
            <div className="columns is-centered " style={{ animationDelay: '1s' }}>
              <div className="column is-full wow animated slideInDown">
                <hr className="dots" />
              </div>
            </div>
            <h4>{t('infoLot')}</h4>
            <div className="columns is-centered is-full is-multiline content-info">
              <div className="column is-full info-lot">
                <div className="columns is-centered is-full is-multiline">
                <div className="column label">{t('date')}</div>
                  <div className="column label">{t('business')}</div>
                  <div className="column label">{t('numberLot')}</div>
                  <div className="column label">{t('status')}</div>
                </div>
              </div>
              <div className="column is-full info-lot">
                <div className="columns is-centered is-full is-multiline">
                  <div className="column">{lot.date}</div>
                  <div className="column">{lot.business}</div>
                  <div className="column">{lot.number}</div>
                  <div className="column">{lot.status}</div>
                </div>
              </div>
            </div>

            <div className="columns is-centered " style={{ animationDelay: '1s' }}>
              <div className="column is-full wow animated slideInDown">
                <hr className="dots" />
              </div>
            </div>
            <h4>{t('transactions')}</h4>
            <TableList
              tableClasses="floating-table"
              totalElements={totalElements}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(
                <tr>
                  <th>{t('date')}</th>
                  <th>{t('cameCode')}</th>
                  <th>{t('user')}</th>
                  <th>{t('transactionType')}</th>
                  <th>
                    {`${t('amount')}`}
                    <div className="text-small">{`(${currency})`}</div>
                  </th>
                  <th>
                    {`${t('charge')}`}
                    <div className="text-small">{`(${currency})`}</div>
                  </th>
                  <th>
                    {`${t('total')}`}
                    <div className="text-small">{`(${currency})`}</div>
                  </th>
                </tr>
              )}
              colNumber={7}
              lenghtData={totalElements}
            >
              {(list !== undefined || list !== '') && (list.map(row => (
                <TransactionRow
                  key={row.id}
                  helper={t('detailReport')}
                >
                  <td>{row.date}</td>
                  <td>{row.code}</td>
                  <td>{row.user}</td>
                  <td>{t(row.type)}</td>
                  <td>
                    <AmountFormat
                      name="total"
                      value={row.amount}
                      displayType="text"
                    />
                  </td>
                  <td><AmountFormat name="charge" value={row.charge} displayType="text" /></td>
                  <td><AmountFormat name="iva" value={row.total} displayType="text" /></td>
                </TransactionRow>
              )))
              }
            </TableList>
          </ContentHeader>
        </Fragment>

      </div>
    );
  }
}

transactionsCommissionsIn.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  getTransactionsLaw: PropTypes.func,
};

transactionsCommissionsIn.defaultProps = {
  showMessage: () => {},
  getTransactionsCommissionIn: () => {},
};

const mapStateToProps = ({ transactions, userSupport, auth }) => (
  {
    transactions,
    userSupport,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...userSupportActions,
    ...authActions,
  }),
  withNamespaces(),
)(transactionsCommissionsIn);
