import React, { Component }                                 from 'react';
import PropTypes                                            from 'prop-types';
import { compose }                                          from 'redux';
import { connect }                                          from 'react-redux';
import { withNamespaces }                                   from 'react-i18next';
import { initTimer, stopTimer }                             from '../../util/initTimer';
import { ContentHeader, DefaultButton, Loading, Tabs }      from '../../components';
import { usersActions }                                     from '../../state/ducks/users';
import { authActions }                                      from '../../state/ducks/auth';
import { agencyActions }                                    from '../../state/ducks/agency';
import {AccountData, AddressData, Declaration, PicturesData, UserData} from './components';
import './style.scss';
import ChargeLey from "./components/chargeLey";

class UserAgencyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTabs: [],
    };
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.handleCreateTabs();
  }

  handleCreateTabs = () => {
    const { identification, users: { userDetail: { type } } } = this.props;
    const listTabs = [
      {
        title: 'userInformation',
        id: 't1',
        content: (
          <UserData
            identification={identification}
          />
        ),
      },
      {
        title: 'detailAddress',
        id: 't2',
        content: (
          <AddressData
            identification={identification}
          />
        ),
      },
      {
        title: 'chargeLey',
        id: 't5',
        content: (
          <ChargeLey
            identification={identification}
          />
        ),
      },

    ];
    if (type === 'Agencia' || type === 'Empresa de Transporte' || type === 'Pasajero') {
      const tab = {
        title: 'detailAccount',
        id: 't3',
        content: (
          <AccountData
            identification={identification}
          />
        ),
      };
      listTabs.push(tab);
    }

    if (type === 'Pasajero') {
      const tab = {
        title: 'userDocumentation',
        id: 't4',
        content: (
          <PicturesData
            identification={identification}
          />
        ),
      };
      listTabs.push(tab);
    }
    if(type !== "Administrador de agencia") {
      const tab = {
        title: 'declaration',
        id: 't6',
        content: (
            <Declaration
                identification={identification}
            />
        ),
      };
      listTabs.push(tab);
    }

    this.setState({
      listTabs
    });
  }


  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  render() {
    const { listTabs } = this.state;
    const { t,
      dateStart, dateEnd, cuit, name, cvu, personType, status,
      handleReturn, users: { loading }, auth: { loading: loadingAuth } } = this.props;

    return (
      <div>
        {(loading || loadingAuth) && <Loading />}
        <ContentHeader
          headingText={(
            <DefaultButton
              name={t('goBack')}
              onClick={() => {
                handleReturn(dateStart, dateEnd, cuit, name, cvu, personType, status);
              }}
            />
          )}
        >
          <Tabs listTabs={listTabs} />
        </ContentHeader>
      </div>
    );
  }
}

UserAgencyEdit.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(UserAgencyEdit);
