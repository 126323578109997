import React, { Component } from 'react';
import PropTypes  from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { toast } from 'react-toastify';
import { initTimer, stopTimer } from '../../../../util/initTimer';
import { Form }  from '../../../../components';
import { ServiceForm } from '../index';
import { configurationsActions } from '../../../../state/ducks/configurations';
import { authActions } from '../../../../state/ducks/auth';
import { agencyActions } from '../../../../state/ducks/agency';
import { TOAST_CONFIG } from '../../../../config/constants';
import { checkPhoneNumber } from '../../../../util';
import { ActionButtons } from '../index';

class UserData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charge: '0',
      iva: '0',
    }
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
  }

  callApiList = async (e) => {
    e.preventDefault();
    const { charge, iva } = this.state;
    const { t, showMessage, editChargesByServices, editComissionByServices, service } = this.props;
    if (service.type === 'charge') {
      editChargesByServices({
        data: {
          id_configuration: service.id_configuration,
          id_business_value: service.id_business_value,
          user_type: service.user_type,
          transaction: service.transaction,
          value: charge.replace(/\./g, '').replace(',', '.'),
          value_tax: iva,
        }, callback: (response) => {
          if (response.success) {
            toast.dismiss();
            showMessage({
              message: response.data.message,
              config: TOAST_CONFIG.SUCCESS,
            });
          } else {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    } else {
      editComissionByServices({
        data: {
          id_configuration: service.id_configuration,
          user_type: service.user_type,
          transaction: service.transaction,
          value: charge,
          value_tax: iva,
        }, callback: (response) => {
          if (response.success) {
            toast.dismiss();
            showMessage({
              message: response.data.message,
              config: TOAST_CONFIG.SUCCESS,
            });
          } else {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }
  };

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    this.setState({
      [name]: targetValue,
    });
  };

  onSubmit = (e) => {
    stopTimer();
    initTimer(this.props);
    e.preventDefault();
    this.callApiList(e);
  };

  render() {
    const { t, service } = this.props;
    const { charge, iva } = this.state;
    const config = {
      charge,
      iva,
    };

    return (
      <div className='pr-16px pl-16px pt-16px content-xl-center'>
        <Form
          submitText={t('save')}
          nameButton='configButton'
          onSubmit={(e) => this.onSubmit(e)}
        >
          <ServiceForm
            handleInputChange={this.handleInputChange}
            config={config}
            title={service.detail}
            chargeCurrent={service.charge}
            ivaCurrent={service.iva}
            editable
          />
        </Form>
      </div>
    );
  }
}

UserData.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ configurations }) => (
  { configurations }
);
export default compose(
  connect(mapStateToProps, {
    ...configurationsActions,
  }),
  withNamespaces(),
)(UserData);
