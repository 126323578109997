import React, { useState } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";
import {usersActions} from "../../../state/ducks/users";
import {Checkbox, FormElement} from "../../../components";

const DeclarationDetailForm = (props) => {
    const {
        t,
        editable,
        afip: {
            idRubro,
            fuLawful,
            peExposed,
            peCause,
            uifObligated,
            uifCause,
        },
        handleInputChange,
        users: {
            rubrosAfip,
        },
        titleLabel,
    } = props;


    return (
        <div className="columns is-multiline">
            <div className="column is-full">
                <h4>{t(titleLabel)}</h4>
                <hr className="dots" />
            </div>
                <FormElement
                    name="idRubro"
                    onChange={(e) => { handleInputChange(e);}}
                    value={idRubro}
                    label={t('rubro')}
                    optionsSelect={rubrosAfip}
                    placeholder={t('selectRubro')}
                    typeElement="select"
                    controlClasses="select select-agency"
                    disabled={!editable}
                />

                <FormElement
                    label={t('illicitFunds')}
                    typeElement="element"
                    value={fuLawful}
                    longColumn={'is-10'}
                >
                    <div className="value-permission">
                        <Checkbox
                            name="fuLawful"
                            defaultValue={fuLawful}
                            value={fuLawful}
                            onChange={(e) => handleInputChange(e)}
                            disabled={!editable}
                        />
                        { t('yes') }
                    </div>
                    <div className="value-permission" style={{marginLeft: '20px'}}>
                        <Checkbox
                            name="fuLawful"
                            defaultValue={!fuLawful}
                            value={!fuLawful}

                            onChange={(e) => handleInputChange(e)}
                            disabled={!editable}
                        />
                        { t('no') }
                    </div>
                </FormElement>

                <FormElement
                    label={t('politicallyExposed')}
                    typeElement="element"
                    value={peExposed}
                    longColumn={'is-10'}
                >
                    <div className="value-permission">
                        <Checkbox
                            name="peExposed"
                            defaultValue={peExposed}
                            value={peExposed}
                            onChange={(e) => handleInputChange(e)}
                            disabled={!editable}
                        />
                        { t('yes') }
                    </div>
                    <div className="value-permission" style={{marginLeft: '20px'}}>
                        <Checkbox
                            name="peExposed"
                            defaultValue={!peExposed}
                            value={!peExposed}
                            onChange={(e) => handleInputChange(e)}
                            disabled={!editable}
                        />
                        { t('no') }
                    </div>
                </FormElement>
                <FormElement
                    name='peCause'
                    onChange={(e) => { handleInputChange(e);}}
                    value={peCause}
                    label={t('motive')}
                    typeElement={'input'}
                    disabled={!peExposed}
                />

                <FormElement
                    label={t('boundSubject')}
                    typeElement="element"
                    value={uifObligated}
                    longColumn={'is-10'}
                >
                    <div className="value-permission">
                        <Checkbox
                            name="uifObligated"
                            defaultValue={uifObligated}
                            value={uifObligated}
                            onChange={(e) => handleInputChange(e)}
                            disabled={!editable}
                        />
                        { t('yes') }
                    </div>
                    <div className="value-permission" style={{marginLeft: '20px'}}>
                        <Checkbox
                            name="uifObligated"
                            defaultValue={!uifObligated}
                            value={!uifObligated}
                            onChange={(e) => handleInputChange(e)}
                            disabled={!editable}
                        />
                        { t('no') }
                    </div>
                </FormElement>
                <FormElement
                    name='uifCause'
                    onChange={(e) => handleInputChange(e)}
                    value={uifCause}
                    label={t('motive')}
                    typeElement={'input'}
                    disabled={!uifObligated}
                />
        </div>
    );

}

const mapStateToProps = ({ users }) => (
    {
        users,
    }
);
export default compose(
    connect(mapStateToProps, {
        ...usersActions,
    }),
    withNamespaces(),
)(DeclarationDetailForm);
