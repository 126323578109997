import React, { Fragment } from 'react';
import { withNamespaces }  from 'react-i18next';
import { compose }         from 'redux';
import { connect }         from 'react-redux';
import { FormElement }     from '../../../components';
import { agencyActions }   from '../../../state/ducks/agency';
import PropTypes           from "prop-types";

const userDetailForm = (props) => {
  const {
    t,
    editable,
    person: {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      email,
    },
    handleInputChange,
    handlePhoneChange,
    cuitText,
    dniText,
    hideEmail,
    handleVerifyCuit,
    title,
  } = props;


  return (
    <Fragment>
      <div className="columns is-multiline">
        <div className="column is-full">
          <h4>{t(title)}</h4>
          <hr className="dots" />
        </div>
        <FormElement
          name='cuit'
          onChange={(e) => {
            handleInputChange(e);
            handleVerifyCuit(e);
          }
          }
          value={cuit}
          label={'CUIL'}
          typeElement={(!editable || cuitText) ? 'text' : 'cuit'}
        />
        <FormElement
          name='dni'
          onChange={(e) => handleInputChange(e)}
          value={dni}
          label={'DNI'}
          typeElement={(!editable || dniText) ? 'text' : 'input'}
          disabled
        />
        <FormElement
          name='name'
          onChange={(e) => handleInputChange(e)}
          value={name}
          label={t('name')}
          typeElement={!editable ? 'text' : 'input'}
        />
        <FormElement
          name='lastname'
          onChange={(e) => handleInputChange(e)}
          value={lastname}
          label={t('lastName')}
          typeElement={!editable ? 'text' : 'input'}
        />
        <FormElement
          name='phoneNumber'
          onChange={(e) => handlePhoneChange(e, 'phoneNumber')}
          value={phoneNumber}
          label={t('phone')}
          typeElement={!editable ? 'text' : 'phone'}
        />
        {!hideEmail &&
        <FormElement
          name='email'
          onChange={(e) => handleInputChange(e)}
          value={email}
          label={t('email')}
          typeElement={!editable ? 'text' : 'email'}
        />
        }
      </div>
    </Fragment>
  );
};

userDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  cuitText: PropTypes.bool,
  dniText: PropTypes.bool,
  hideEmail: PropTypes.bool,
  title: PropTypes.string,
  person: PropTypes.shape({
    dni: PropTypes.string.isRequired,
    cuit: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }),
  handleInputChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
};

userDetailForm.defaultProps = {
  editable: false,
  cuitText: false,
  dniText: false,
  hideEmail: false,
  person: {},
  title: 'legalRepresentant',
  handleInputChange: null,
  handlePhoneChange: null,
};
const mapStateToProps = ({ agency }) => (
  {
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...agencyActions,
  }),
  withNamespaces(),
)(userDetailForm);

