import types            from './types';
import { initialState } from '../../../App';

const userSupport = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR:
    {
      return {
        ...state, ...initialState.userSupport,
      };
    }
    case types.START_FETCH:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH:
    {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_TRANSACTIONS_CLAIM:
    {
      const { data: { currency, totalList, listClaim } } = action.payload;
      return {
        ...state,
        transactionsClaim: {
          currency,
          totalElements: totalList,
          list: listClaim || [],
        },
      };
    }
    case types.UPDATE_TRANSACTIONS_CLAIM_EXCEL:
    {
      const { headers, title, rows } = action.payload;
      return {
        ...state,
        transactionsClaimExcel: {
          title,
          headers,
          rows: rows || [],
        },
      };
    }
    case types.UPDATE_TRANSACTIONS_COMMISSION:
    {
      const { payload, payload: { data: { count_transactions, transactions, commission } } } = action;
      return {
        ...state,
        transactionsCommission: {
          totalElements: count_transactions,
          list: transactions || [],
          commission: commission || [],
        },
      };
    }
    case types.UPDATE_TRANSACTIONS_COMMISSION_EXCEL:
    {
      const { headers, title, rows } = action.payload;
      return {
        ...state,
        transactionsCommissionExcel: {
          title,
          headers,
          rows: rows || [],
        },
      };
    }
    case types.UPDATE_TRANSACTION_TYPES :
      {
        const { data } = action.payload;
        return {
          ...state,
          transactionsTypes: data,
        };
      }
    case types.UPDATE_TRANSACTIONS_LAW:
    {
      const { payload, payload: { data: { total, list } } } = action;
      return {
        ...state,
        transactionsLaw: {
          totalElements: total || 0,
          list: list || [],
        },
      };
    }
    case types.UPDATE_TRANSACTIONS_COMMISSION_IN:
    {
      const { data:{ count, list, lot }  } = action.payload;
      return {
        ...state,
        transactionsCommissionIn: {
          totalElements: count || 0,
          list: list || [],
          lot: lot || {}
        },
      };
    }
    case types.UPDATE_TRANSACTIONS_COMMISSION_LOT:
    {
      const {  data: { count, list }  } = action.payload;
      return {
        ...state,
        transactionsCommissionLot: {
          totalElements: count || 0,
          list: list || [],
        },
      };
    }
    case types.UPDATE_TRANSPORT_COMPANY_LOT :
    {
      const { data: {company} } = action.payload;
      return {
        ...state,
        transportCompanyLot : company || [],
      };
    }
    default:
      return state;
  }
};

export default userSupport;
