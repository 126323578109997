import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { withNamespaces }             from 'react-i18next';
import { compose }                    from 'redux';
import { initTimer, stopTimer }       from '../../util/initTimer';
import { LIST_MONTH, TOAST_CONFIG }   from '../../config/constants';
import {
  AmountFormat,
  ContentHeader,
  DefaultButton,
  Filter,
  FormElement,
  Loading,
  Panel,
  TableList,
  Toggle,
  TransactionRow,
}                                     from '../../components';
import './styles.scss';
import { usersActions }               from '../../state/ducks/users';
import { authActions }                from '../../state/ducks/auth';
import { Link }                       from "react-router-dom";

class TransactionsPerUser extends Component {
  constructor(props) {
    super(props);
    const now = new Date;
    this.state = {
      email: '',
      identification: '',
      month: now.getMonth() + 1,
      errorEmail: '',
      currentPage: 0
    };
  }

  componentDidMount() {
    const { clearUsers, location: { state } } = this.props;
    stopTimer();
    initTimer(this.props);
    clearUsers();
    if (state !== undefined) {
      const { email, identification } = state;
      if (email !== undefined && identification !== undefined) {
        this.setState({ email, identification }, () => {this.callApiList()});
      }
    }
  }

  onChangePage = (page) => {
    stopTimer();
    initTimer(this.props);
    this.setState({currentPage: page.selected})
    this.callApiList(page.selected);
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    const { email } = this.state;
    const { t } = this.props;
    this.setState({currentPage: 0})

    if (email.trim() !== '') {
      this.callApiList();
      this.setState({ errorEmail: '' })
    } else {
      this.setState({ errorEmail: t('obligatoryField') })
    }
  };

  onChangeMonth = (month) => {
    this.setState(() => ({
      month,
    }));
  }

  callApiList = (page = 0) => {
    const { t, fetchTransactionsPerUser, showMessage } = this.props;
    const {
      month,
      email,
      identification,
    } = this.state;
    fetchTransactionsPerUser({
      page,
      email,
      month,
      identification,
      callback: ({ data, success }) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  render() {
    const {
      t,
      auth: { account: { product: currency } },
      users: { transactionPerUser: { list, totalElements }, loading }
    } = this.props;
    const {
      month,
      email,
      errorEmail,
      currentPage
    } = this.state;
    return (
      <div className="transactions-external-accounts">
        {loading && <Loading />}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => (
            <Fragment>
              {toggled && (
                <Fragment>
                  <Panel
                    headingRightText={t('detailMovement')}
                    titleContentHeader={<DefaultButton name={t('goBack')} onClick={onToggle} />}
                    headingText={t('detailMovement')}
                    buttonReturn={{ label: '' }}
                  >
                    <div className="p-16px content-xl-center">
                      <div className="columns is-multiline">
                        <div className="column is-full">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('dateTransaction')}
                              :
                            </span>
                            <span className="has-text-grey">
                              {toggleProps.created_at || t('notAvailable')}
                            </span>
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              CVU:
                            </span>
                            <span className="has-text-grey">
                              {toggleProps.cvu || t('notAvailable')}
                            </span>
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('username')}
                              :
                            </span>
                            <span className="has-text-grey">
                              {toggleProps.username || t('notAvailable')}
                            </span>
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('name')}
                              :
                            </span>
                            <span className="has-text-grey">
                              {toggleProps.name || t('notAvailable')}
                            </span>
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('lastName')}
                              :
                            </span>
                            <span className="has-text-grey">
                              {toggleProps.lastname || t('notAvailable')}
                            </span>
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('causesBind')}
                              :
                            </span>
                            <span className="has-text-grey">
                              {toggleProps.causeValue || t('notAvailable')}
                            </span>
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('url')}
                              :
                            </span>
                            <span className="has-text-grey">
                              {toggleProps.url || t('notAvailable')
                              }
                            </span>
                          </div>
                        </div>
                        <div className="column is-full">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('senderBind')}
                              :
                            </span>
                            <p className="has-text-grey wrap-text">
                              {toggleProps.send_bind || t('notAvailable')
                              }
                            </p>
                          </div>
                        </div>
                        <div className="column is-full">
                          <div className="pt-8px">
                            <span className="pr-8px">
                              {t('receiverBind')}
                              :
                            </span>
                            <p className="has-text-grey wrap-text">
                              {toggleProps.receive_bind || t('notAvailable')
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Fragment>
              )}
              {!toggled && (
                <Fragment>
                  <div className="wow animated slideInDown">
                    <Filter
                      title={t('transactionsPerUserList')}
                      subtitle={t('filters')}
                      onSubmit={(ev) => { this.onSubmit(ev); }}
                    >
                      <FormElement
                        name="email"
                        onChange={this.handleInputChange}
                        value={email}
                        label={t('email')}
                        helpText={errorEmail}
                      />
                      <FormElement
                        name="month"
                        onChange={this.handleInputChange}
                        value={month}
                        label={t('month')}
                        optionsSelect={LIST_MONTH}
                        typeElement="select"
                        disabledNullSelect
                        controlClasses="select"
                      />
                    </Filter>
                  </div>
                  <ContentHeader
                    headingText={
                      <Link to={{
                        pathname: '/virtual-account-balance',
                      }}
                      >
                        <DefaultButton name={t('goBack')} />
                      </Link>
                    }
                    headingRightText={t('totalTransactions')}
                    headingRightSubText={`${totalElements}`}
                  >
                    <TableList
                      tableClasses="floating-table"
                      totalElements={totalElements}
                      onChangePage={this.onChangePage}
                      forcePage={currentPage}
                      head={(
                        <tr>
                          <th>{t('dateTransaction')}</th>
                          <th>{t('cameCode')}</th>
                          <th>{t('transactionType')}</th>
                          <th>{t('detail')}</th>
                          <th>{t('state')}</th>
                          <th>{`${t('amount')} (${currency})`}</th>
                          <th>{`${t('balanceText')} (${currency})`}</th>
                          {/*<th />*/}
                        </tr>
                      )}
                      colNumber={7}
                      lenghtData={totalElements !== undefined ? totalElements : 0}
                    >
                      {list && (list.map(row => (
                        <TransactionRow
                          key={row.id}
                          helper={t('detailReport')}
                          /*openDetail={() => {
                            onToggle(row);
                            stopTimer();
                            initTimer(this.props);
                          }}*/
                        >
                          <td>{row.transaction_date}</td>
                          <td>{row.transaction_code}</td>
                          <td>{row.transaction_name}</td>
                          <td>{row.transaction_detail || '--'}</td>
                          <td>{t(row.transaction_status)}</td>
                          <td>
                            <AmountFormat
                              name="amount"
                              value={row.transaction_amount}
                              displayType="text"
                            />
                          </td>
                          <td>
                            <AmountFormat
                              name="balance"
                              value={row.balance}
                              displayType="text"
                              allowNegative
                            />
                          </td>
                        </TransactionRow>
                      )))
                      }
                    </TableList>
                  </ContentHeader>
                </Fragment>
              )}
            </Fragment>
          )}
        </Toggle>
      </div>
    );
  }
}

TransactionsPerUser.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  clearUsers: PropTypes.func,
  fetchTransactionsPerUser: PropTypes.func,
  users: PropTypes.shape({
    loading: PropTypes.bool,
    transactionPerUser: PropTypes.shape({
      totalElements: PropTypes.number,
      list: PropTypes.array,
    }),
  }).isRequired,
};

TransactionsPerUser.defaultProps = {
  showMessage: () => {},
  fetchTransactionsPerUser: () => {},
  clearUsers: () => {},
};

const mapStateToProps = ({ users, auth }) => (
  {
    users,
    auth,
  }
);

export default compose(connect(mapStateToProps, { ...usersActions, ...authActions }), withNamespaces())(
  TransactionsPerUser,
);
