import React              from 'react';
import * as Excel         from 'exceljs/dist/exceljs';
import { withNamespaces } from 'react-i18next';
import { saveAs }         from 'file-saver';
import { LogoBase64 }     from '../../util/imagesBase64/logo';
import PropTypes          from "prop-types";
import './styles.scss';

const ExportExcel = (props) => {
  const {
    headers, rows, title, t, onclick, text
  } = props;
  const nameColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const handleGenerateExcel = () => {
    const date = (new Date()).getTime();
    const name = `${title.replace(/ /g, '_')}_${date}`;
    const headerTable = [];
    const columns = headers.map((item) => {
      headerTable.push(item.header);
      item.header = '';
      return (item);
    });
    const countColumns = headerTable.length;
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(title);
    const logo = workbook.addImage({
      base64: LogoBase64,
      extension: 'png',
    });
    worksheet.addImage(logo, {
      tl: { col: 0, row: 0.2 },
      ext: { width: 113.20, height: 64.15 },
    });
    worksheet.columns = columns;
    worksheet.properties.defaultColWidth = 15;
    worksheet.mergeCells(`A1:${nameColumns[countColumns - 1]}3`);
    worksheet.getCell('A1').value = title;
    worksheet.getRow(1).font = { nametitle: 'Nexa Bold', family: 4, size: 16, bold: true, };
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    worksheet.getRow(5).values = headerTable;
    for (let i = 0; i < countColumns; i += 1) {
      worksheet.getColumn(nameColumns[i]).alignment = { vertical: 'middle', horizontal: 'center' };
      const modifyColumn = `${nameColumns[i]}5`;
      worksheet.getCell(modifyColumn).font = {
        name: 'Nexa Bold', family: 4, size: 13, bold: true, color: { argb: 'FFFFFFFF' },
      };
      worksheet.getCell(modifyColumn).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.getCell(modifyColumn).fill = {
        type: 'pattern',
        pattern: 'lightTrellis',
        fgColor: { argb: 'FFD8560C' },
        bgColor: { argb: 'FFD8560C' },
      };
    }
    worksheet.addRows(rows);
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      let dataMax = 0;
      const column = worksheet.columns[i];
      for (let j = 5; j < column.values.length; j += 1) {
        if (typeof column.values[j] !== 'undefined') {
          const columnLength = column.values[j].length;
          if (columnLength > dataMax) {
            dataMax = columnLength + 5;
          }
        }
      }
      column.width = dataMax < 10 ? 10 : dataMax;
    }
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${name}.xlsx`);
    });
  };
  return (
    <button
      onClick={() => {
        if (onclick) {
          onclick();
        } else {
          handleGenerateExcel();
        }
      }}
      className="button is-success button-excel"
      type="button"
    >
      <i className="far fa-file-excel mr-4px" /> {text ?? t('exportExcel')}
    </button>
  );
};
ExportExcel.propTypes = {
  t: PropTypes.func.isRequired,
  onclick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

ExportExcel.defaultProps = {
  onclick: null,
};

export default withNamespaces()(ExportExcel);