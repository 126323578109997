import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const generateExcelReport = createAction(types.GENERATE_EXCEL_TRANSACTIONS)
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const fetchTransactions = createAction(types.FETCH_TRANSACTIONS);
const fetchTransactionsConsole = createAction(types.FETCH_TRANSACTIONS_CONSOLE);
const checkCbu = createAction(types.CHECK_CBU);
const updateCbu = createAction(types.UPDATE_CBU);
const saveIndividualTransaction = createAction(types.SAVE_INDIVIDUAL_TRANSACTION);
const saveMultipleTransaction = createAction(types.SAVE_MULTIPLE_TRANSACTION);
const checkMultipleTransaction = createAction(types.CHECK_MULTIPLE_TRANSACTION);
const setMultipleTransaction = createAction(types.SET_MULTIPLE_TRANSACTION);
const updateMultipleTransaction = createAction(types.UPDATE_MULTIPLE_TRANSACTION);
const editMultipleTransaction = createAction(types.EDIT_MULTIPLE_TRANSACTION);
const responseMultipleTransaction = createAction(types.RESPONSE_MULTIPLE_TRANSACTION);
const updateTransactionLot = createAction(types.UPDATE_TRANSACTION_LOT);
const extractQr = createAction(types.EXTRACT);
const transfer = createAction(types.TRANSFER);
const updateTransactions = createAction(types.UPDATE_TRANSACTIONS);
const clear = createAction(types.CLEAR);
const authorizeTransaction = createAction(types.AUTHORIZE_TRANSACTION);
const checkBalance = createAction(types.CHECK_BALANCE);
const updateCharge = createAction(types.UPDATE_CHARGE);
const updateBalance = createAction(types.UPDATE_BALANCE);
const getListMultipleTransaction = createAction(types.GET_LIST_MULTIPLE_TRANSACTION);
const updateListMultipleTransaction = createAction(types.UPDATE_LIST_MULTIPLE_TRANSACTION);
const getElementMultipleTransaction = createAction(types.GET_ELEMENT_MULTIPLE_TRANSACTION);
const updateElementMultipleTransaction = createAction(types.UPDATE_ELEMENT_MULTIPLE_TRANSACTION);
const updateElementListMultipleTransaction = createAction(types.UPDATE_ELEMENT_LIST_MULTIPLE_TRANSACTION);
const getAccountBalanceFundraiser = createAction(types.GET_ACCOUNT_BALANCE_FUNDRAISER);
const updateBalanceFundraiser = createAction(types.UPDATE_BALANCE_FUNDRAISER);
const fetchListPrisma = createAction(types.FETCH_LIST_PRISMA);
const updateListPrisma = createAction(types.UPDATE_LIST_PRISMA);
const fetchListStatePrisma = createAction(types.FETCH_LIST_STATE_PRISMA);
const updateListStatePrisma = createAction(types.UPDATE_LIST_STATE_PRISMA);
const fetchTransactionTypes = createAction(types.FETCH_TRANSACTION_TYPES);
const updateTransactionTypes = createAction(types.UPDATE_TRANSACTION_TYPES);
const fetchListDongle = createAction(types.FETCH_LIST_DONGLE);
const updateListDongle = createAction(types.UPDATE_LIST_DONGLE);
const updateListDongleExcel = createAction(types.UPDATE_LIST_DONGLE_EXCEL);
const fetchListAllPrisma = createAction(types.FETCH_LIST_ALL_PRISMA);
const setListAllPrisma = createAction(types.SET_LIST_ALL_PRISMA);
const fetchTransactionsSusa = createAction(types.FETCH_TRANSACTIONS_SUSA);
const setTransactionsSusa = createAction(types.SET_TRANSACTIONS_SUSA);
const depositBusiness = createAction(types.DEPOSIT_BUSINESS);
const withdrawalBusiness = createAction(types.WITHDRAWAL_BUSINESS);
const fetchListCommissions = createAction(types.FETCH_LIST_COMMISSIONS);
const setListCommissions = createAction(types.SET_LIST_COMMISSIONS);
const fetchCommissionTypes = createAction(types.FETCH_COMMISSION_TYPES);
const setCommissionTypes = createAction(types.SET_COMMISSION_TYPES);
const fetchCommissionStatus = createAction(types.FETCH_COMMISSION_STATUS);
const setCommissionStatus = createAction(types.SET_COMMISSION_STATUS);
const fetchExtractionsList = createAction(types.FETCH_EXTRACTIONS_LIST);
const updateExtractionsList = createAction(types.UPDATE_EXTRACTIONS_LIST);
const fetchStatusExtractions = createAction(types.FETCH_STATUS_EXTRACTION);
const updateStatusExtractions = createAction(types.UPDATE_STATUS_EXTRACTION);
const fetchTypeUserExtractions = createAction(types.FETCH_TYPE_USER_EXTRACTION);
const updateTypeUserExtractions = createAction(types.UPDATE_TYPE_USER_EXTRACTION);
const fetchQrOperationsList = createAction(types.FETCH_QR_OPERATIONS_LIST);
const updateQrOperationsList = createAction(types.UPDATE_QR_OPERATIONS_LIST);
const fetchTypeUserQr = createAction(types.FETCH_TYPE_USER_QR);
const updateTypeUserQr = createAction(types.UPDATE_TYPE_USER_QR);
const fetchStatusQr = createAction(types.FETCH_STATUS_QR);
const updateStatusQr = createAction(types.UPDATE_STATUS_QR);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  fetchTransactions,
  generateExcelReport,
  fetchTransactionsConsole,
  updateCbu,
  saveIndividualTransaction,
  saveMultipleTransaction,
  setMultipleTransaction,
  checkMultipleTransaction,
  updateMultipleTransaction,
  editMultipleTransaction,
  responseMultipleTransaction,
  updateTransactionLot,
  getListMultipleTransaction,
  getElementMultipleTransaction,
  updateListMultipleTransaction,
  updateElementMultipleTransaction,
  updateElementListMultipleTransaction,
  checkCbu,
  extractQr,
  transfer,
  updateTransactions,
  clear,
  showMessage,
  authorizeTransaction,
  checkBalance,
  updateCharge,
  updateBalance,
  getAccountBalanceFundraiser,
  updateBalanceFundraiser,
  fetchListPrisma,
  updateListPrisma,
  fetchListStatePrisma,
  updateListStatePrisma,
  fetchTransactionTypes,
  updateTransactionTypes,
  fetchListDongle,
  updateListDongle,
  updateListDongleExcel,
  fetchListAllPrisma,
  setListAllPrisma,
  fetchTransactionsSusa,
  setTransactionsSusa,
  depositBusiness,
  withdrawalBusiness,
  fetchListCommissions,
  setListCommissions,
  fetchCommissionTypes,
  setCommissionTypes,
  fetchCommissionStatus,
  setCommissionStatus,
  fetchExtractionsList,
  updateExtractionsList,
  fetchStatusExtractions,
  updateStatusExtractions,
  fetchTypeUserExtractions,
  updateTypeUserExtractions,
  fetchQrOperationsList,
  updateQrOperationsList,
  fetchTypeUserQr,
  updateTypeUserQr,
  fetchStatusQr,
  updateStatusQr,
};
