import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect }         from 'react-redux';
import {
  InfoAgency,
  conciliationList,
  Dashboard,
  DepositWithdrawalMoney,
  DetailConciliation,
  DetailTransation,
  Layout,
  MovementAgencyList,
  Profile,
  Rates,
  SignIn,
  TransationsReport,
  TransportCompanyForm,
  UserAgencyForm,
  UserOperatorForm,
  UsersAdmin,
  UsersOperator,
  VirtualAccountBalance,
  TransactionsPerUser,
  BoxAgencyList,
  DetailBoxAgency,
  LotsInfo,
  AccountInfo,
  ListCommissions,
  AgencyList,
  OperatorsList,
  CompanyList,
  UserAgencyEdit,
  UserOperatorEdit,
  TransactionsBind,
  DetailTransactionBind,
  Switch,
  DetailTransactionSwitch,
  RetreatsList,
  DetailRetreats,
  ListQr,
  DetailQr,
  StateAccounts,
  AuthExtractions,
  SettingCharges,
  SettingsEdit,
  transactionsCommission,
  transactionsLaw25413,
  transactionsCommissionIn,
  transactionsCommissionLot,
  VirtualAccountMovements,

} from '../scenes';
import { AccountDetails }  from "../scenes/infoAgency";

const mapStateToProps = ({ auth }) => ({
  auth,
});

export const privateRoute = WrappedComponent => connect(mapStateToProps)(({ auth, ...rest }) => (auth.logged ===
  true ?
    (<WrappedComponent auth={auth} {...rest} />) :
    (<Redirect to="/login" />)
));

export const RouteWithSubRoutes = route => (<Route
  path={route.path}
  exact={route.exact}
  render={props => (<route.component {...props} {...route} />)}
/>);

export const NotFound = () => (<Redirect to="/dashboard" />);

const routes = [
  {
    path: '/login',
    component: SignIn,
    exact: true,
  }, {
    path: '/',
    component: privateRoute(Layout),
    routes: [
      {
        path: '/dashboard',
        component: privateRoute(Dashboard),
        exact: true,
      }, {
        path: '/profile',
        component: privateRoute(Profile),
        exact: true,
      }, {
        path: '/users-admin',
        component: privateRoute(UsersAdmin),
        exact: true,
      }, {
        path: '/users-operator',
        component: privateRoute(UsersOperator),
        exact: true,
      }, {
        path: '/transactions-report',
        component: privateRoute(TransationsReport),
        exact: true,
      }, {
        path: '/detail-transaction',
        component: privateRoute(DetailTransation),
        exact: true,
      }, {
        path: '/rates',
        component: privateRoute(Rates),
        exact: true,
      }, {
        path: '/virtual-account-balance',
        component: privateRoute(VirtualAccountBalance),
        exact: true,
      }, {
        path: '/conciliate',
        component: privateRoute(conciliationList),
        exact: true,
      }, {
        path: '/detail-conciliation',
        component: privateRoute(DetailConciliation),
        exact: true,
      }, {
        path: '/deposit-withdrawal-money',
        component: privateRoute(DepositWithdrawalMoney),
        exact: true,
      }, {
        path: '/info-agency',
        component: privateRoute(InfoAgency),
        exact: true,
      }, {
        path: '/lots-info',
        component: privateRoute(LotsInfo),
        exact: true,
      }, {
        path: '/account-info',
        component: privateRoute(AccountInfo),
        exact: true,
      }, {
        path: '/account-details',
        component: privateRoute(AccountDetails),
        exact: true,
      }, {
        path: '/movement-agency-list',
        component: privateRoute(MovementAgencyList),
        exact: true,
      }, {
        path: '/transactions-per-user',
        component: privateRoute(TransactionsPerUser),
        exact: true,
      }, {
        path: '/list-commissions',
        component: privateRoute(ListCommissions),
        exact: true,
      }, {
        path: '/box-agency',
        component: privateRoute(BoxAgencyList),
        exact: true,
      }, {
        path: '/detail-box-agency',
        component: privateRoute(DetailBoxAgency),
        exact: true,
      }, {
        path: '/agency-registration',
        component: privateRoute(UserAgencyForm),
        exact: true,
      }, {
        path: '/operator-registration',
        component: privateRoute(UserOperatorForm),
        exact: true,
      }, {
        path: '/transport-company-registration',
        component: privateRoute(TransportCompanyForm),
        exact: true,
      },{
        path: '/agency-list',
        component: privateRoute(AgencyList),
        exact: true,
      },{
        path: '/operators-list',
        component: privateRoute(OperatorsList),
        exact: true,
      },{
        path: '/company-list',
        component: privateRoute(CompanyList),
        exact: true,
      }, {
        path: '/edit-agency',
        component: privateRoute(UserAgencyEdit),
        exact: true,
      }, {
        path: '/edit-operator',
        component: privateRoute(UserOperatorEdit),
        exact: true,
      },{
        path: '/transactions-bind',
        component: privateRoute(TransactionsBind),
        exact: true,
      }, {
        path: '/detail-transaction-bind',
        component: privateRoute(DetailTransactionBind),
        exact: true,
      },{
        path: '/switch',
        component: privateRoute(Switch),
        exact: true,
      },{
        path: '/detail-switch',
        component: privateRoute(DetailTransactionSwitch),
        exact: true,
      }, {
        path: '/list-extractions',
        component: privateRoute(RetreatsList),
        exact: true,
      },
      {
        path: '/detail-extraction',
        component: privateRoute(DetailRetreats),
        exact: true,
      },
      {
        path: '/operations-qr',
        component: privateRoute(ListQr),
        exact: true,
      },
      {
        path: '/detail-qr',
        component: privateRoute(DetailQr),
        exact: true,
      },
      {
        path: '/state-accounts',
        component: privateRoute(StateAccounts),
        exact: true,
      },
      {
        path: '/extraction-accounts',
        component: privateRoute(AuthExtractions),
        exact: true,
      },
      {
        path: '/settings-charges-iva',
        component: privateRoute(SettingCharges),
        exact: true,
      },
      {
        path: '/edit-charge',
        component: privateRoute(SettingsEdit),
        exact: true,
      },
      {
        path: '/transactions-commissions',
        component: privateRoute(transactionsCommission),
        exact: true,
      },
      {
        path: '/transactions-law25413',
        component: privateRoute(transactionsLaw25413),
        exact: true,
      },
      {
        path: '/transactions-commissions-in',
        component: privateRoute(transactionsCommissionIn),
        exact: true,
      },
      {
        path: '/transactions-commissions-lot',
        component: privateRoute(transactionsCommissionLot),
        exact: true,
      },
      {
        path: '/account-virtual-movements',
        component: privateRoute(VirtualAccountMovements),
        exact: true,
      },

    ],
  }, {
    component: NotFound,
  },
];

export default routes;
