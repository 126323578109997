import React, { useState } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";
import {usersActions} from "../../../../state/ducks/users";
import {Form, FormElement} from "../../../../components";
import Checkbox from "../../../../components/Checkbox";
import {toast} from "react-toastify";
import {TOAST_CONFIG} from "../../../../config/constants";

const Declaration = (props) => {
    const {
        t,
        identification,
        users: {
            userDetail: {afip_rg, type},
            rubrosAfip,
        },
        updateAfipData,
        showMessage,
    } = props;

    const [state, setState] = useState({
        idRubro: afip_rg.id_rubro,
        fuLawful: afip_rg.fu_lawful,
        peExposed: afip_rg.pe_exposed,
        peCause: afip_rg.pe_cause,
        uifObligated: afip_rg.uif_obligated,
        uifCause: afip_rg.uif_cause,
    });

    const handleInputChange = (event) => {
        const {
            target: {
                value: targetValue, name,
            },
        } = event;
        setState((prevState) =>
            ({ ...prevState,
            [name]: targetValue,
        }));
    };

    const onsubmit = (event) => {
        event.preventDefault();

        const data = {
            id_person: identification,
            afip_rg: {
                id_rubro: parseInt(state.idRubro),
                pe_exposed: state.peExposed ? 1 :0,
                pe_cause: state.peCause || '',
                fu_lawful: state.fuLawful ? 1 : 0,
                uif_obligated: state.uifObligated ? 1 : 0,
                uif_cause: state.uifCause || '',
            }
        }
        updateAfipData({
            data,
            callback: (response) => {
                if (response.success) {
                    toast.dismiss();
                    showMessage({
                        message: t('saveAfipData'),
                        config: TOAST_CONFIG.SUCCESS,
                    });
                } else {
                    toast.dismiss();
                    showMessage({
                        message: response.data.message ?? t('saveAfipDataError'),
                        config: TOAST_CONFIG.ERROR,
                    });
                }
            },
        });
    }


    return (
        <div className="p-16px content-xl-center">
            <Form
                submitText={t('save')}
                onSubmit={(event) => {
                    onsubmit(event);
                }}
                nameButton="configButton"
                //disabled={type === 'Pasajero'}
            >
                <FormElement
                    name="idRubro"
                    onChange={(e) => { handleInputChange(e);}}
                    value={state.idRubro}
                    label={t('rubro')}
                    optionsSelect={rubrosAfip}
                    placeholder={t('selectRubro')}
                    typeElement="select"
                    controlClasses="select select-agency"
                />

                <div className="column is-full">
                    <h4>{t('declaration')}</h4>
                    <hr className="dots" />
                </div>
                <FormElement
                    label={t('illicitFunds')}
                    typeElement="element"
                    value={state.fuLawful}
                    longColumn={'is-10'}
                >
                    <div className="value-permission">
                        <Checkbox
                            name="fuLawful"
                            defaultValue={state.fuLawful}
                            value={state.fuLawful}
                            onChange={(e) => {
                                setState((prevState) => ({ ...prevState, fuLawful:true }));
                            }}
                        />
                        { t('yes') }
                    </div>
                    <div className="value-permission" style={{marginLeft: '20px'}}>
                        <Checkbox
                            name="fuLawful"
                            defaultValue={!state.fuLawful}
                            value={!state.fuLawful}
                            onChange={(e) => {
                                setState((prevState) => ({ ...prevState, fuLawful: false }));
                            }}
                        />
                        { t('no') }
                    </div>
                </FormElement>

                <FormElement
                    label={t('politicallyExposed')}
                    typeElement="element"
                    value={state.peExposed}
                    longColumn={'is-10'}
                >
                    <div className="value-permission">
                        <Checkbox
                            name="peExposed"
                            defaultValue={state.peExposed}
                            value={state.peExposed}
                            onChange={(e) => {
                                setState((prevState) => ({ ...prevState, peExposed: true }));
                            }}
                        />
                        { t('yes') }
                    </div>
                    <div className="value-permission" style={{marginLeft: '20px'}}>
                        <Checkbox
                            name="peExposed"
                            defaultValue={!state.peExposed}
                            value={!state.peExposed}
                            onChange={(e) => {
                                setState((prevState) => ({ ...prevState, peExposed: false }));
                            }}
                        />
                        { t('no') }
                    </div>
                </FormElement>
                <FormElement
                    name='peCause'
                    onChange={(e) => { handleInputChange(e);}}
                    value={state.peCause}
                    label={t('motive')}
                    typeElement={'input'}
                    disabled={!state.peExposed}
                />

                <FormElement
                    label={t('boundSubject')}
                    typeElement="element"
                    value={state.peExposed}
                    longColumn={'is-10'}
                >
                    <div className="value-permission">
                        <Checkbox
                            name="uifObligated"
                            defaultValue={state.uifObligated}
                            value={state.uifObligated}
                            onChange={(e) => {
                                setState((prevState) => ({ ...prevState, uifObligated:true }));
                            }}
                        />
                        { t('yes') }
                    </div>
                    <div className="value-permission" style={{marginLeft: '20px'}}>
                        <Checkbox
                            name="uifObligated"
                            defaultValue={!state.uifObligated}
                            value={!state.uifObligated}
                            onChange={(e) => {
                                setState((prevState) => ({ ...prevState, uifObligated: false }));
                            }}
                        />
                        { t('no') }
                    </div>
                </FormElement>
                <FormElement
                    name='uifCause'
                    onChange={(e) => { handleInputChange(e);}}
                    value={state.uifCause}
                    label={t('motive')}
                    typeElement={'input'}
                    disabled={!state.uifObligated}
                />
            </Form>
        </div>
    );

}

const mapStateToProps = ({ users }) => (
    {
        users,
    }
);
export default compose(
    connect(mapStateToProps, {
        ...usersActions,
    }),
    withNamespaces(),
)(Declaration);
