import React, { Fragment } from 'react';
import PropTypes           from 'prop-types';
import { withNamespaces }  from 'react-i18next';
import { Link }            from 'react-router-dom';
import Menu                from './Menu';
import { Toggle }          from '../../../../components';
import { OptionLogout }    from '..';
import { IMG }             from '../../../../config/constants';
import './styles.scss';

const onClick2 = (event) => {
  const target = event.target.getBoundingClientRect();
  const boxElement = document.getElementsByClassName('boxSetAsi')[0];
  let y = 0;
  if (window.innerWidth > 768) {
    y = target.top + 40;
  } else {
    y = target.top + 50;
  }
  boxElement.style.top = `${y}px`;
};

const Aside = (props) => {
  const {
    t, location: { pathname }, name, logout, operator,
  } = props;
  const menu = [
    {
      url: '/users-admin',
      icon: 'fas fa-users',
      name: 'userAdmin',
    }, {
      url: '/users-operator',
      icon: 'fas fa-users-cog',
      name: 'userOperator',
      disabled: operator,
    },
    {
      url: '/transactions-bind',
      name: 'transactionsBind',
      icon: 'fas fa-money-bill-wave',
    },
    {
      url: '#',
      icon: 'far fa-plus-square',
      name: 'registerRedSusa',
      submenu: {
        items: [
          {
            id: 'sm4-1',
            url: '/agency-registration',
            icon: 'fas fa-house-user',
            name: 'agency',
          },
          {
            id: 'sm4-2',
            url: '/operator-registration',
            icon: 'fas fa-user-cog',
            name: 'operator',
          },
          {
            id: 'sm4-3',
            url: '/transport-company-registration',
            icon: 'fas fa-shuttle-van',
            name: 'transportCompany',
          },
        ],
      },
    },
    {
      url: '#',
      icon: 'fas fa-laptop',
      name: 'adminRed',
      submenu: {
        items: [
          {
            id: 'sm5-1',
            url: '/info-agency',
            name: `${t('infoAgency')}`,
            icon: 'fas fa-info',
          },
          // {
          //   id:' sm5-2',
          //   url: '#',
          //   name: 'netSusa',
          //   icon: 'fas fa-code-branch',
          // },
          {
            id: 'sm5-3',
            url: '/agency-list',
            name: `${t('listAgency')}`,
            icon: 'far fa-list-alt',
          },
          {
            id: 'sm5-4',
            url: '/operators-list',
            name: `${t('operatorsList')}`,
            icon: 'fas fa-user-tie',
          },
          {
            id: 'sm5-5',
            url: '/company-list',
            name: `${t('companyList')}`,
            icon: 'fas fa-bus-alt',
          },
        ],
      },
    },
    {
      url: '#',
      icon: 'fas fa-laptop-house',
      name: 'adminNegocio',
      submenu: {
        items: [
          {
            id: 'sm3-1',
            url: '/virtual-account-balance',
            icon: 'fas fa-file-invoice',
            name: 'virtualAccountBalance',
          },
          {
            id: 'sm3-2',
            url: '/conciliate',
            icon: 'fas fa-file-invoice-dollar',
            name: 'conciliation',
          },
          {
            id: 'sm3-3',
            url: '/transactions-report',
            icon: 'far fa-file-alt',
            name: 'transactionsReport',
          },
          {
            id: 'sm3-4',
            url: '/list-commissions',
            icon: 'fas fa-search-dollar',
            name: 'commissionList',
          },
          {
            id: 'sm3-5',
            url: '/list-extractions',
            icon: 'fas fa-external-link-alt',
            name: 'listExtractions',
          },
          {
            id: 'sm3-6',
            url: '/operations-qr',
            icon: 'fas fa-qrcode',
            name: 'operationsQr',
          },
          {
            id: 'sm3-7',
            url: '/state-accounts',
            icon: 'far fa-folder-open',
            name: 'statesAccounts',
          },
          {
            id: 'sm3-8',
            url: '/transactions-commissions',
            icon: 'fas fa-file-invoice-dollar',
            name: 'commissions',
          },
       /*   {
            id: 'sm3-10',
            url: '/transactions-commissions-in',a
            icon: 'fas fa-file-invoice-dollar',
            name: 'commissionsIn',
          }, */
         /* {
            id: 'sm3-11',
            url: '/transactions-commissions-lot',
            icon: 'fas fa-file-invoice-dollar',
            name: 'commissionsLot',
          }, */

          {
            id: 'sm3-9',
            url: '/transactions-law25413',
            icon: 'fas fa-file-invoice-dollar',
            name: 'law25413',
          },

        ],
      },
    },
    {
      url: '#',
      icon: 'fas fa-cogs',
      name: 'adminGeneral',
      submenu: {
        items: [
          {
            id: 'sm6-1',
            url: '/deposit-withdrawal-money',
            icon: 'fas fa-money-check-alt',
            name: 'depositWithdrawalMoney',
            disabled: operator,
          },
          {
            url: '/switch',
            name: 'Switch',
            icon: 'fas fa-random',
          },
        ],
      },
    },
    {
      url: '/settings-charges-iva',
      icon: 'fas fa-sliders-h',
      name: 'configChargesIva',
      disabled: operator,
    },
  ];
  return (
    <div className="Aside">
      <Toggle>
        {({
            toggled, toggledMenu, onToggle, exitToggle, exitToggleDrop, onToggleMenu, exitToggleMenu,
          }) => (
          <Fragment>
            <div className="be-left-sidebar">
              <div className="left-sidebar-wrapper">
                <div className="left-sidebar-toggle">
                  <div className="user verticalCenter">
                    <div className="avatar">
                      <li className="dropdown">
                        <Link to="/profile">
                          <button tabIndex={-1} className="dropdown-toggle">
                            <img
                              src={IMG.avatar}
                              alt="Avatar minified"
                            />
                          </button>
                        </Link>
                      </li>
                    </div>
                    <div className="profile">
                      <div className="welcome">{t('welcome')}</div>
                      <div
                        className="username"
                      >
                        {`${t('user')}: ${name}`}
                      </div>
                    </div>
                  </div>
                  <div className="menu verticalCenter alignCustom">
                    <span
                      role="presentation"
                      onClick={(event) => {
                        exitToggleMenu();
                        exitToggleDrop();
                        onToggle();
                      }}
                      className="presentation"
                    >
                      <i className="fas fa-bars" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="logout verticalCenter">
                    <div className="logout-arrow verticalCenter">
                      <li className="dropdown">
                        <button
                          tabIndex={-1}
                          className="dropdown-toggle text-decoration-none"
                          onClick={(event) => {
                            onClick2(event);
                            exitToggle();
                            exitToggleDrop();
                            onToggleMenu();
                          }}
                          onBlur={() => toggledMenu
                            && setTimeout(onToggleMenu, 500)}
                        >
                          <img
                            src={IMG.arrowDownOutline}
                            alt="Avatar minified"
                          />
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
                <div className={`${toggled ? 'show-up-down' : 'hide-down-up'}`}>
                  <ul className="sidebar-elements">
                    {menu.map((value, i) => (
                      <Menu
                        key={`menu-${i}`}
                        name={value.name}
                        icon={value.icon}
                        url={value.url}
                        pathname={pathname}
                        submenu={value.submenu}
                        disabled={value.disabled}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <OptionLogout
              t={t}
              name={name}
              logout={logout}
              toggled={toggledMenu}
              tclass="boxSetAsi"
            />
          </Fragment>
        )}
      </Toggle>
    </div>
  );
};

Aside.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default withNamespaces()(Aside);
