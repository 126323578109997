import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';
import ReactTooltip         from 'react-tooltip';
import { withNamespaces }   from 'react-i18next';
import PhoneInput           from 'react-phone-number-input';
import AmountFormat         from '../AmountFormat';
import TextInput            from '../TextInput';
import Datepicker           from '../Datepicker';
import 'react-phone-number-input/style.css';
import './styles.scss';
import { SelectSearchable } from "../index";
import NumberFormat         from "react-number-format";

/**
 * Created by Feedback Software on 25/10/18.
 * @param t
 * @param name
 * @param placeholder
 * @param value
 * @param onChange function to handle the state in the father component
 * @param onBlur function to handle the state in the father component
 * @param type
 * @param helpText
 * @param label if empty doesn't show
 * @param disabled boolean to disable input
 * @param fieldClasses array of classes for the field
 * @param inputClasses array of classes for the input
 * @param controlClasses array of classes for the control
 * @param icon font awesome class of icon to display
 * @param iconPosition can receive 'is-left' or 'is-right' to position as class
 * @param min minimal amount used when the input is number
 * @param step incremental amount
 * @param labelClasses
 * @param information
 * @param typeElement
 * @param disabledNullSelect
 * @param optionsSelect
 * @param displayTypeAmount
 * @param currency
 * @param allowNegative
 * @param maxDate
 * @param minDate
 * @param maxDetail
 * @param showMonthYearPicker
 * @param showTimeSelect
 * @param dateFormat
 * @param longColumn
 * @param children
 * @returns {*}
 * @constructor
 */
const FormElement = ({
                       t,
                       name,
                       placeholder,
                       value,
                       onChange,
                       onBlur,
                       type,
                       helpText,
                       disabled,
                       fieldClasses,
                       inputClasses,
                       controlClasses,
                       icon,
                       iconPosition,
                       min,
                       step,
                       labelClasses,
                       label,
                       information,
                       typeElement,
                       disabledNullSelect,
                       optionsSelect,
                       displayTypeAmount,
                       currency,
                       allowNegative,
                       maxDate,
                       minDate,
                       maxDetail,
                       showMonthYearPicker,
                       showTimeSelect,
                       dateFormat,
                       longColumn,
                       children,
                       maxLength,
                     }) => (
  <Fragment>
    <div className={`column ${longColumn} is-paddingless`}>
      <div className="fields is-horizontal">
        <div className="field-label is-normal">
          <label
            className={`label ${labelClasses}`}
            htmlFor={name}
          >
            <span>
              {label}
              {information && (
                <i
                  className="ml-8px fas fa-info-circle has-text-warning"
                  aria-hidden="true"
                  data-for={`tooltip-${labelClasses}`}
                  data-tip="Test"
                >
                  <ReactTooltip
                    id={`tooltip-${labelClasses}`}
                    type="warning"
                    effect="solid"
                  >
                    {information}
                  </ReactTooltip>
                </i>
              )
              }
            </span>
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className={`custom-field column ${fieldClasses}`}>
              <div className={`control ${controlClasses}`}>
                {typeElement === 'input' && (
                  <>
                    <input
                      className={`input ${inputClasses}`}
                      name={name}
                      id={name}
                      placeholder={placeholder}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      type={type}
                      disabled={disabled}
                      min={min}
                      step={step}
                      maxLength={maxLength}
                    />
                    {helpText &&
                    <p className="help has-text-danger">{helpText}</p>}
                  </>
                )}
                {typeElement === 'amountFormat' && (
                  <AmountFormat
                    customInput={TextInput}
                    displayType={displayTypeAmount}
                    prefix={`${currency} `}
                    value={value}
                    allowNegative={allowNegative}
                    name={name}
                    label={label}
                    onChange={onChange}
                    onBlur={onBlur}
                    controlclasses={controlClasses}
                    inputclasses={inputClasses}
                    fieldClasses={fieldClasses}
                    disabled={disabled}
                  />
                )}
                {typeElement === 'datepicker' && (
                  <Datepicker
                    onChange={onChange}
                    selected={value}
                    placeholderText={placeholder}
                    maxDate={maxDate}
                    minDate={minDate}
                    maxDetail={maxDetail}
                    showMonthYearPicker={showMonthYearPicker}
                    showTimeSelect={showTimeSelect}
                    dateFormat={dateFormat}
                  />
                )}
                {typeElement === 'select' && (
                  <select
                    name={name}
                    className={`input ${inputClasses}`}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                  >
                    {!disabledNullSelect
                    && (
                      <option value="">
                        {placeholder}
                      </option>
                    )
                    }
                    {optionsSelect && optionsSelect.map((option, key) => (
                      <option
                        key={option.value || option.id || key}
                        value={option.value || option.id || key}
                        disabled={option.disabled}
                      >
                        {option.text || option.name || t(option.nameTranslate) || option}
                      </option>
                    ))
                    }
                  </select>
                )}
                {typeElement === 'text' && (
                  <p className="is-text">{value}</p>
                )}
                {typeElement === 'phone' && (
                  <PhoneInput
                    name={name}
                    value={value}
                    onChange={onChange}
                    defaultCountry="AR"
                    country={'AR'}
                    countries={['AR']}
                    className={`input-dimentions input-border ${disabled ?? 'disabled-input'}`}
                    disabled={disabled}
                    maxLength="18"
                  />
                )}
                {typeElement === 'selectSearchable' && (
                  <SelectSearchable
                    name={name}
                    options={optionsSelect}
                    onChange={onChange}
                    value={value}
                    fieldClasses="register-field"
                    disabled={disabled}
                  />
                )}
                {typeElement === 'cuit' && (
                  <>
                    <NumberFormat
                      value={value}
                      name={name}
                      onChange={onChange}
                      thousandSeparator=""
                      decimalSeparator=" "
                      customInput={TextInput}
                      decimalScale={0}
                      format='###########'
                      allowNegative={false}
                      fixedDecimalScale
                    />
                    {helpText &&
                    <p className="help has-text-danger">{helpText}</p>}
                  </>
                )}
                {typeElement === 'email' && (
                  <>
                    <input
                      className={`input ${inputClasses}`}
                      name={name}
                      id={name}
                      placeholder={placeholder}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      type={type}
                      disabled={disabled}
                      maxLength="64"
                    />
                    {helpText &&
                    <p className="help has-text-danger">{helpText}</p>}
                  </>
                )}
                {typeElement === 'element' && (
                  <>
                    {children}
                  </>
                )}
                {
                  !!icon && (
                    <span className={`icon ${iconPosition}`}>
                      <i className={`fa ${icon}`} />
                    </span>
                  )
                }
              </div>
              <p className="help has-text-danger">{helpText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Fragment>
);

FormElement.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  helpText: PropTypes.string,
  currency: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disabledNullSelect: PropTypes.bool,
  allowNegative: PropTypes.bool,
  fieldClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  controlClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  information: PropTypes.string,
  typeElement: PropTypes.string,
  displayTypeAmount: PropTypes.string,
  icon: PropTypes.string,
  longColumn: PropTypes.string,
  iconPosition: PropTypes.string,
  dateFormat: PropTypes.string,
  optionsSelect: PropTypes.arrayOf(PropTypes.object),
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  step: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxDetail: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  showMonthYearPicker: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  maxLength: PropTypes.number,
};

FormElement.defaultProps = {
  placeholder: null,
  value: undefined,
  type: 'text',
  onChange: () => {
  },
  onBlur: () => {
  },
  helpText: null,
  label: null,
  disabled: false,
  disabledNullSelect: false,
  fieldClasses: '',
  inputClasses: '',
  controlClasses: '',
  labelClasses: '',
  information: '',
  currency: '',
  longColumn: 'is-6',
  typeElement: 'input',
  icon: null,
  iconPosition: null,
  min: null,
  step: null,
  optionsSelect: [],
  allowNegative: false,
  displayTypeAmount: '',
  maxDate: new Date(),
  minDate: null,
  maxDetail: 'year',
  dateFormat: 'dd/MM/yyyy',
  showTimeSelect: false,
  showMonthYearPicker: false,
  maxLength: 144,
};
export default withNamespaces()(FormElement);
