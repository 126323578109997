import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import ReactTooltip                   from 'react-tooltip';
import { transactionsActions }        from '../../state/ducks/transactions';
import { userSupportActions }         from '../../state/ducks/userSupport';
import {
  AmountFormat,
  ContentHeader,
  ExportExcel,
  Filter,
  FormElement,
  Loading,
  TableList,
  TransactionRow,
}                                     from '../../components';
import {
  initTimer,
  stopTimer,
}                                     from '../../util/initTimer';

import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../config/constants';
import { authActions }                 from '../../state/ducks/auth';
import { formatDate }                  from '../../util';
import './styles.scss';

class transactionsLaw25413 extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      dateStart: '',
      dateEnd: '',
      cameCode: '',
      document: '',
      name: '',
      page: 0,
      transactionType: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.callApiList = this.callApiList.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.callApiList();

    const {getTransactionTypes} = this.props;

    getTransactionTypes();
  }

  onChangePage = (selectPage) => {
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  callApiList = (getExcel = false) => {
    const {
      t, getTransactionsLaw, showMessage, userSupport: {
        transactionsLaw: { totalElements }
      }
    } = this.props;
    const {
      dateStart, dateEnd, cameCode, document, name, page, transactionType
    } = this.state;

    getTransactionsLaw({
      data: {
        date_ini: formatDate(dateStart),
        date_end: formatDate(dateEnd),
        code: cameCode,
        document,
        name,
        page: getExcel ? 0 : page,
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        export: getExcel,
        transaction_type: transactionType,
      },
      callback: (data) => {
        const { success } = data.data;
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  render() {
    const {
      t,
      userSupport: {
        transactionsLaw: { totalElements, list },
        transactionsTypes,
        loading,
      }, auth: { account: { product: currency } },
    } = this.props;


    const {
      dateStart, dateEnd, cameCode, document, name, page, transactionType,
    } = this.state;
    return (
      <div className="transaction-law">
        {loading && <Loading />}
        <Fragment>
          <div className="wow animated slideInDown">
            <Filter
              title={t('transactionsLaw')}
              subtitle={t('filters')}
              onSubmit={(ev) => { this.onSubmit(ev); }}
            >
              <FormElement
                name='dateStart'
                onChange={ev => this.onChangeDate(ev, 'dateStart')}
                label={t('dateStart')}
                value={dateStart}
                typeElement='datepicker'
                maxDate={dateEnd || new Date()}
              />
              <FormElement
                name='dateEnd'
                onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                value={dateEnd}
                label={t('dateEnd')}
                typeElement='datepicker'
                maxDate={new Date()}
                minDate={dateStart}
              />
              <FormElement
                name="cameCode"
                onChange={this.handleInputChange}
                value={cameCode}
                label={t('cameCode')}
              />
              <FormElement
                name="document"
                onChange={this.handleInputChange}
                value={document}
                label={t('document')}
              />
              <FormElement
                name="name"
                onChange={this.handleInputChange}
                value={name}
                label={t('name')}
                information={`${t('name')} / ${t('lastName')} / ${t('businessName')}`}
              />
              <FormElement
                name='transactionType'
                onChange={this.handleInputChange}
                value={transactionType}
                label={t('transactionType')}
                optionsSelect={transactionsTypes}
                placeholder={t('select')}
                typeElement="select"
                controlClasses="select"
              />
            </Filter>

          </div>
          <ContentHeader
            headingRightText={t('totalTransactions')}
            headingRightSubText={totalElements}
          >
            <ExportExcel
              onclick={() => { this.callApiList(true); }}
            />
            <TableList
              tableClasses="floating-table"
              totalElements={totalElements}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(
                <tr>
                  <th>{t('date')}</th>
                  <th>{t('cameCode')}</th>
                  <th>{t('document')}</th>
                  <th>
                    <span>
                      {t('name')}
                      <i
                        className="ml-8px fas fa-info-circle has-text-warning"
                        aria-hidden="true"
                        data-for="tooltip-name"
                        data-tip="Test"
                      >
                        <ReactTooltip
                          id="tooltip-name"
                          type="warning"
                          effect="solid"
                        >
                          {`${t('fullName')} / ${t('businessName')}`}
                        </ReactTooltip>
                      </i>
                    </span>
                  </th>
                  <th>{t('transactionType')}</th>
                  <th>
                    {`${t('totalTaxLaw')}`}
                    <div className="text-small">{`(${currency})`}</div>
                  </th>

                </tr>
              )}
              colNumber={6}
              lenghtData={totalElements}
            >
              {(list !== undefined || list !== '') && (list.map(row => (
                <TransactionRow
                  key={row.id}
                  helper={t('detailReport')}
                >
                  <td>{row.date}</td>
                  <td>{row.code}</td>
                  <td>{row.document_number}</td>
                  <td>{row.name + ' ' + row.lastname}</td>
                  <td>{t(row.type)}</td>
                  <td>
                    <AmountFormat
                      name="total"
                      value={row.charge}
                      displayType="text"
                    />
                  </td>
                </TransactionRow>
              )))
              }
            </TableList>
          </ContentHeader>
        </Fragment>

      </div>
    );
  }
}

transactionsLaw25413.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  getTransactionsLaw: PropTypes.func,
};

transactionsLaw25413.defaultProps = {
  showMessage: () => {},
  getTransactionsLaw: () => {},
};

const mapStateToProps = ({ transactions, userSupport, auth }) => (
  {
    transactions,
    userSupport,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...userSupportActions,
    ...authActions,
  }),
  withNamespaces(),
)(transactionsLaw25413);
