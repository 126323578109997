import React, { Component }     from 'react';
import { toast }                from 'react-toastify';
import PropTypes                from 'prop-types';
import { compose }              from 'redux';
import {connect} from "react-redux";
import {usersActions} from "../../../../state/ducks/users";
import {authActions} from "../../../../state/ducks/auth";
import {agencyActions} from "../../../../state/ducks/agency";
import {withNamespaces} from "react-i18next";
import {configurationsActions} from "../../../../state/ducks/configurations";
import {initTimer, stopTimer} from "../../../../util/initTimer";
import Checkbox from "../../../../components/Checkbox";
import {Form, FormElement} from "../../../../components";
import {TOAST_CONFIG} from "../../../../config/constants";

class ChargeLey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chargeIn: false,
      cahgeOut: false,

    };
  };

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = () => {
    const { t, showMessage, chargesLaw, identification: id_person } = this.props;

    chargesLaw({
      data: { id_person },
      callback: (response) => {
        const {success, data} = response;
        if (success) {
          this.setState({
            chargeIn: data.charge_in_law_301,
            chargeOut: data.charge_out_law_301
          })
        }
      }
      });

  }

  onsubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();

    const  {chargeIn, chargeOut} = this.state;

    const { t, showMessage, updateChargesLaw, identification: id_person } = this.props;

    const data = {
      id_person: id_person,
      charge_in_law_301: chargeIn ? 1 : 0,
      charge_out_law_301: chargeOut ? 1 : 0
    }


    updateChargesLaw({
      data,
      callback: (response) => {

        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('saveUserCharges'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserChargesError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      }
    })


  }

  render() {
    const  {chargeIn, chargeOut} = this.state;
    const {t} = this.props;

    return(
      <div className="p-16px content-xl-center">
        <Form
          submitText={t('save')}
          onSubmit={(event) => {
            this.onsubmit(event);
          }}
          nameButton="configButton"
        >

        <FormElement
          label={t('chargeLawIn')}
          typeElement="element"
          value={chargeIn}
          longColumn={'is-10'}
        >
          <Checkbox
            name="chargeIn"
            defaultValue={chargeIn}
            value={chargeIn}
            onChange={(e) => {
              this.setState({chargeIn: !chargeIn});
            }}
          />
          <div className="value-permission">
            {chargeIn ? t('active') : t('inactive')}
          </div>
        </FormElement>

        <FormElement
          label={t('chargeLawOut')}
          typeElement="element"
          value={chargeOut}
          longColumn={'is-10'}
        >
          <Checkbox
            name="chargeOut"
            defaultValue={chargeOut}
            value={chargeOut}
            onChange={(e) => {
              this.setState({chargeOut: !chargeOut});
            }}
          />
          <div className="value-permission">
            {chargeOut ? t('active') : t('inactive')}
          </div>
        </FormElement>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ users, auth }) => (
  {
    users,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
  }),
  withNamespaces(),
)(ChargeLey);
