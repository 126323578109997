import React, { Component }                      from 'react';
import PropTypes                                 from 'prop-types';
import { compose }                               from 'redux';
import { connect }                               from 'react-redux';
import { withNamespaces }                        from 'react-i18next';
import { initTimer, stopTimer }                  from '../../util/initTimer';
import { ContentHeader, DefaultButton, Loading } from '../../components';
import { usersActions }                          from '../../state/ducks/users';
import { authActions }                           from '../../state/ducks/auth';
import { agencyActions }                         from '../../state/ducks/agency';
import './style.scss';
import UserData                                  from './components/userData';
import Tabs                                      from '../../components/Tabs';
import { AddressData }                           from './components';

class UserAgencyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTabs: [],
    };
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.handleCreateTabs();
    const {fetchRubrosAfip} = this.props;
    fetchRubrosAfip();
  }

  handleCreateTabs = () => {
    const { identification } = this.props;
    const listTabs = [
      {
        title: 'agencyData',
        id: 't1',
        content: (
          <UserData
            identification={identification}
          />
        ),
      },
      {
        title: 'detailAddress',
        id: 't2',
        content: (
          <AddressData
            identification={identification}
          />
        ),
      },
    ];

    this.setState({
      listTabs
    });
  }


  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  render() {
    const { listTabs } = this.state;
    const { t, handleReturn, users: { loading }, auth: { loading: loadingAuth } } = this.props;

    return (
      <div>
        {(loading || loadingAuth) && <Loading />}
        <ContentHeader
          headingText={(
            <DefaultButton
              name={t('goBack')}
              onClick={handleReturn}
            />
          )}
        >
          <Tabs listTabs={listTabs} />
        </ContentHeader>
      </div>
    );
  }
}

UserAgencyEdit.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(UserAgencyEdit);
