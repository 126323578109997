import * as fileSaver                             from 'file-saver';
import { transactionsActions, transactionsTypes } from '../ducks/transactions';
import { API_URL, HEADERS }                       from '../../config/constants';
import { authActions }                            from '../ducks/auth';
import {usersActions} from "../ducks/users";

const fetchTransactions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TRANSACTIONS) {
    return;
  }
  const { page = 0, month = 0, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/user-last-transactions?page=${page}&month=${month}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateTransactions(data.data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const checkCbu = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.CHECK_CBU) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/verification-alias-cbu',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.hasOwnProperty('data')
          && data.hasOwnProperty('success') && !data.success)
        {
          const str = data.data.message;
          const result = str.replace('Alias', 'CBU/CVU o Alias');
          data.data.message = result;
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const saveIndividualTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.SAVE_INDIVIDUAL_TRANSACTION) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/transaction-bind-cvu',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const setMultipleTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.SET_MULTIPLE_TRANSACTION) {
    return;
  }
  const {
    element, token, expToken, callback,
  } = action.payload || {};
  const formData = new FormData();
  formData.append('filecsv', element[0]);
  fetch(`${API_URL}api/validation-file`, {
    method: 'POST',
    body: formData,
    headers: HEADERS({
      token,
      expToken,
    }),
  })
    .then(response => response.json())
    .then((response) => {
      callback(response);
      dispatch(transactionsActions.updateMultipleTransaction(response.data));
      dispatch(authActions.updateExpToken({ expToken: response.data.expToken }));
    })
    .catch((response) => {
      callback(response);
    });
};

const editMultipleTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.EDIT_MULTIPLE_TRANSACTION) {
    return;
  }
  dispatch(transactionsActions.updateMultipleTransaction(action.payload));
};

const saveMultipleTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.SAVE_MULTIPLE_TRANSACTION) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/validation-data-transfer',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.updateMultipleTransaction(data.data));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const responseMultipleTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.RESPONSE_MULTIPLE_TRANSACTION) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/transaction-multiple',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateTransactionLot(data.data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const checkMultipleTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.CHECK_MULTIPLE_TRANSACTION) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/transaction-multiple',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateMultipleTransaction(data.data));
        callback(data);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const extractQr = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.EXTRACT) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/user-qr',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const transfer = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.TRANSFER) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/user-transaction',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const authorizeTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.AUTHORIZE_TRANSACTION) {
    return;
  }

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/authorize-transaction',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.updateCharge(data.data));
          dispatch(transactionsActions.updateBalance(data.data));
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const checkBalance = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.CHECK_BALANCE) {
    return;
  }
  const {
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/check-balance',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.updateBalance(data.data));
          callback();
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};
const getAccountBalanceFundraiser = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.GET_ACCOUNT_BALANCE_FUNDRAISER) {
    return;
  }
  const {
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/get-account-balance-fundraiser',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data !== null && data.success) {
          const balance = data.data[0].balance.amount;
          dispatch(transactionsActions.updateBalanceFundraiser({ balance }));
          callback();
        } else {
          const balance = data.data.message || 'Error conectando con bind';
          dispatch(transactionsActions.updateBalanceFundraiser({ balance }));
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const getListMultipleTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.GET_LIST_MULTIPLE_TRANSACTION) {
    return;
  }
  const {
    data, callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/lots-transactions',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(transactionsActions.updateListMultipleTransaction(data.data));
          callback(data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const getElementMultipleTransaction = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.GET_ELEMENT_MULTIPLE_TRANSACTION) {
    return;
  }
  const {
    data,
    check,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/lot-transaction-detail',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          callback(data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const generateExcelReport = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsTypes.GENERATE_EXCEL_TRANSACTIONS) {
    return;
  }
  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/transactions-urban-request-excel-services',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          callback(data);
        }
      },
      onEnd: transactionsActions.endFetch,
    },
  });
}

const fetchTransactionsConsole = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TRANSACTIONS_CONSOLE) {
    return;
  }
  const { page = 0, month = 0, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/transactions-console?page=${page}&month=${month}`,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateTransactions(data.data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchListPrisma = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_LIST_PRISMA) {
    return;
  }
  const { data: dataSend, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/payment-button-transactions',
        data: dataSend,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Transacciones_boton_pago.xlsx');
        } else {
          const { data } = responseData;
          dispatch(transactionsActions.updateListPrisma(data));
          callback(data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchListStatePrisma = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_LIST_STATE_PRISMA) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/status-payment-button',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateListStatePrisma(data.data));
      },
      onError: (response) => {
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchTransactionTypes = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TRANSACTION_TYPES) {
    return;
  }
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/transaction-types-console',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateTransactionTypes(data.data));
      },
      onError: (response) => {
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchListDongle = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_LIST_DONGLE) {
    return;
  }
  const { data: dataSend, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/operations-dongle',
        data: dataSend,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Pagos_dongle.xlsx');
        } else {
          const { data } = responseData;
          dispatch(transactionsActions.updateListDongle({ data }));
          callback(data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchListAllPrisma = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_LIST_ALL_PRISMA) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/payment-button-transactions',
        data,
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.setListAllPrisma(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchTransactionsSusa = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TRANSACTIONS_SUSA) {
    return;
  }
  const {data: dataSend, callback, data: { export: exportExcel }} = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/transactions-urban-services',
        data: dataSend,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        if (exportExcel) {
          const blob = data
          const file = new Blob([blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          fileSaver.saveAs(file, 'Reporte de transacciones.xlsx');
        } else {
          dispatch(transactionsActions.setTransactionsSusa(data));
          callback(data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const depositBusiness = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.DEPOSIT_BUSINESS) {
    return;
  }
  const {data, callback} = action.payload || {};

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/deposit-to-business',
        data
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
          callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const withdrawalBusiness = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.WITHDRAWAL_BUSINESS) {
    return;
  }
  const {data, callback} = action.payload || {};

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/withdrawal-to-business',
        data
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};


const fetchListCommissions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_LIST_COMMISSIONS) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/commissions-urban-services',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Comisiones por cobro de pasaje.xlsx');
        } else {
          const commissions = responseData.data.data;
          dispatch(transactionsActions.setListCommissions({ data: commissions }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchCommissionStatus = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_COMMISSION_STATUS) {
    return;
  }
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-status-commissions',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.setCommissionStatus(data));
      },
      onError: (response) => {
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchCommissionTypes = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_COMMISSION_TYPES) {
    return;
  }
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/transaction-types',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.setCommissionTypes(data));
      },
      onError: (response) => {
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchExtractionsList = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_EXTRACTIONS_LIST) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/list-extractions',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Listado de extracciones.xlsx');
        } else {
          const extractions = responseData.data.data;
          dispatch(transactionsActions.updateExtractionsList({ data: extractions }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchStatusExtractions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_STATUS_EXTRACTION) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-status-extraction',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateStatusExtractions(data));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchTypeUserExtractions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TYPE_USER_EXTRACTION) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-user-type-extraction',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateTypeUserExtractions(data));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchQrOperationsList = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_QR_OPERATIONS_LIST) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};

  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/list-qr-operations',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Listado de pagos con qr.xlsx');
        } else {
          const qrs = responseData.data.data;
          dispatch(transactionsActions.updateQrOperationsList({ data: qrs }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchStatusQr = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_STATUS_QR) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-status-qr',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateStatusQr(data));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

const fetchTypeUserQr = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsTypes.FETCH_TYPE_USER_QR) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: transactionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-user-type-qr',
      },
      authorization: true,
      onStart: transactionsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(transactionsActions.updateTypeUserQr(data));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsActions.endFetch,
    },
  });
};

export default [
  generateExcelReport,
  fetchTransactions,
  fetchTransactionsConsole,
  extractQr,
  transfer,
  authorizeTransaction,
  checkBalance,
  checkCbu,
  saveIndividualTransaction,
  setMultipleTransaction,
  editMultipleTransaction,
  saveMultipleTransaction,
  getListMultipleTransaction,
  getElementMultipleTransaction,
  // checkMultipleTransaction,
  responseMultipleTransaction,
  getAccountBalanceFundraiser,
  fetchListStatePrisma,
  fetchListPrisma,
  fetchTransactionTypes,
  fetchListDongle,
  fetchListAllPrisma,
  fetchTransactionsSusa,
  depositBusiness,
  withdrawalBusiness,
  fetchListCommissions,
  fetchCommissionTypes,
  fetchCommissionStatus,
  fetchExtractionsList,
  fetchStatusExtractions,
  fetchTypeUserExtractions,
  fetchQrOperationsList,
  fetchStatusQr,
  fetchTypeUserQr,
];
